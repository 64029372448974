import { postJSON, getJSON } from 'src/core/util/loaders';
import {
  LOGIN,
  REQUEST_PASSWORD_RESET,
  LOGOUT,
  RESET_LOGIN_FORM,
  SET_USER,
} from './login.constant';

const loginSuccess = (payload) => ({
  type: LOGIN.SUCCESS,
  payload,
});

const loginError = (error) => ({
  type: LOGIN.ERROR,
  payload: error,
});

export const login =
  ({ email, password }) =>
  (dispatch) => {
    const request = postJSON(`${process.env.REACT_APP_API_ROOT}/iatf/login`, {
      email,
      password,
    })
      .then((session) => {
        localStorage.setItem('token', session.token);
        return dispatch(loginSuccess(session));
      })
      .catch((error) => {
        return dispatch(loginError(error));
      });

    dispatch({
      type: LOGIN.START,
      payload: request,
    });

    return request;
  };

const requestResetSuccess = (payload) => ({
  type: REQUEST_PASSWORD_RESET.SUCCESS,
  payload,
});

const requestResetError = (error) => ({
  type: REQUEST_PASSWORD_RESET.ERROR,
  payload: error,
});

export const requestPasswordReset = (email) => (dispatch) => {
  const request = postJSON(
    `${process.env.REACT_APP_API_ROOT}/password-reset/send-code`,
    {
      email,
    },
  )
    .then((session) => {
      return dispatch(requestResetSuccess(session));
    })
    .catch((error) => {
      return dispatch(requestResetError(error));
    });

  dispatch({
    type: REQUEST_PASSWORD_RESET.START,
    payload: request,
  });

  return request;
};

export const logout = () => ({ type: LOGOUT });

export const reset = () => ({ type: RESET_LOGIN_FORM });

export const getSession = () => (dispatch) => {
  const request = getJSON(`${process.env.REACT_APP_API_ROOT}/auth/session`)
    .then((sessionUser) => {
      return dispatch(setUser(sessionUser));
    })
    .catch((error) => {
      console.log(error);
    });

  return request;
};

export const setUser = (payload) => ({
  type: SET_USER,
  payload,
});
