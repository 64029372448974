import { createAsyncActionTypes } from 'src/core/util/async-action-type-creator';

export const GET_COMPANIES = createAsyncActionTypes('GET_COMPANIES');

export const venuesConfig = [
  {
    id: 'ID',
    label: 'VenueID',
  },
  {
    id: 'Name',
    label: 'Venue Name',
  },
  {
    id: 'Address',
    label: 'Address',
    format: ({ Premise, Street, Locality, Region, Country }) =>
      `${Premise || ''} ${Street || ''} ${Locality || ''} ${Region || ''} ${
        Country || ''
      }`,
  },
];

export const PEOPLE = [
  {
    id: 1,
    name: 'John Doe',
    address: 'johndoe@batlgrounds.com',
  },
  {
    id: 1,
    name: 'Jane Doe',
    address: 'janedoe@batlgrounds.com',
  },
];

export const peopleConfig = [
  {
    id: 'ID',
    label: 'Access ID',
  },
  {
    id: 'Email',
    label: 'Email',
  },
  {
    id: 'Roles',
    label: 'Roles',
    format: (v, { Super, GM, Manager, Runner, Scoring, Blocked }) => {
      const roles = { Super, GM, Manager, Runner, Scoring, Blocked };
      var value = Object.keys(roles).reduce(
        (acc, key, index, arr) => (roles[key] ? acc + key + ', ' : acc),
        '',
      );
      return value.substring(0, value.length - 2);
    },
  },
];
