import { buildUrl } from "../../../../util/url.util";
import { endpoints } from "../../../../core/constants/url.constant";
import { GET_COMPANIES } from "./company.constant";
import { getJSON } from "../../../../util/loaders";

const getCompaniesSuccess = (payload) => ({
  type: GET_COMPANIES.SUCCESS,
  payload,
});

const getCompaniesError = (error) => ({
  type: GET_COMPANIES.ERROR,
  payload: error,
});

export const getCompanies = () => (dispatch) => {
  const request = getJSON(
    buildUrl(endpoints.billing.companies.getCompanies),
    {},
    {}
  )
    .then((res) => {
      return dispatch(getCompaniesSuccess(res));
    })
    .catch((error) => {
      return dispatch(getCompaniesError(error));
    });

  return dispatch({
    type: GET_COMPANIES.START,
    payload: request,
  });
};
