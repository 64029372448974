import styled from 'styled-components';

export const MainWrapper = styled.div`
  flex-grow: 1;
  min-height: calc(100vh);
  background: #f5f5f5;
`;

export const Spacer = styled.div`
  ${({ toolbar }) => toolbar}
`;
