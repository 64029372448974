import { path } from 'ramda';
import { createStructuredSelector, createSelector } from 'reselect';
import {
  selectCountries,
  selectFeatures,
  selectRegions,
} from 'src/scenes/companies/store/companies.selector';

export const selectCompany = path(['companyDetail', 'company']);
export const selectLoading = path(['companyDetail', 'loading']);
export const selectAccess = createSelector(
  path(['companyDetail', 'access']),
  (access) => access.sort((a, b) => (a.Email > b.Email ? 1 : -1)),
);
export const selectVenues = createSelector(
  path(['companyDetail', 'venues']),
  (venues) => venues.sort((a, b) => (a.Name > b.Name ? 1 : -1)),
);

export const companyDetailConnector = createStructuredSelector({
  company: selectCompany,
  access: selectAccess,
  venues: selectVenues,
  loading: selectLoading,
  countries: selectCountries,
  regions: selectRegions,
  features: selectFeatures,
});
