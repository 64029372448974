import { path } from 'ramda';
import { createStructuredSelector } from 'reselect';

export const selectToken = path(['auth', 'token']);
export const selectLoggedInUser = path(['auth', 'user']);
export const selectIsLoggedIn = path(['auth', 'isLoggedIn']);
export const selectIsLoading = path(['login', 'isLoading']);
export const selectError = path(['login', 'error']);
export const selectPasswordResetRequested = path([
  'login',
  'passwordResetRequested',
]);

export const loginConnector = createStructuredSelector({
  user: selectLoggedInUser,
  token: selectToken,
  isLoggedIn: selectIsLoggedIn,
  loading: selectIsLoading,
  error: selectError,
  passwordResetRequested: selectPasswordResetRequested,
});
