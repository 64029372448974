import { getJSON, postJSON } from 'src/core/util/loaders';
import { GET_COMPANIES } from './companies.constant';

const getCompaniesSuccess = (payload) => ({
  type: GET_COMPANIES.SUCCESS,
  payload,
});

// const getVenuesError = (error) => ({
//   type: GET_VENUES.ERROR,
//   payload: error,
// });

export const getCompanies = () => (dispatch) => {
  const request = getJSON(
    `${process.env.REACT_APP_API_ROOT}/iatf/admin/companies`,
  )
    .then((payload) => {
      return dispatch(getCompaniesSuccess(payload.companies));
    })
    .catch((error) => {
      console.log(error);
      return error;
    });

  dispatch({
    type: GET_COMPANIES.START,
    payload: request,
  });

  return request;
};

export const createCompany = (payload) => (dispatch) => {
  const request = postJSON(
    `${process.env.REACT_APP_API_ROOT}/iatf/admin/company`,
    payload,
  )
    .then((payload) => {
      console.log(payload);
      return dispatch(getCompanies());
    })
    .catch((error) => {
      console.log(error);
      return error;
    });

  dispatch({
    type: GET_COMPANIES.START,
    payload: request,
  });

  return request;
};
