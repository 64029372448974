export const getFeatures = (bit, features) => {
  const value = bit.toString(2).split('').reverse();
  let selected = [];

  value.forEach((bit, index) => {
    if (bit !== '0') {
      selected.push(Math.pow(2, index));
    }
  });

  return selected;
};
