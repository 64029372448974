import React from 'react';
import * as Yup from 'yup';

import {
  Typography,
  FormControl,
  InputLabel,
  Input,
  Button,
} from '@material-ui/core';
import { Formik, ErrorMessage } from 'formik';
import { Redirect } from 'react-router-dom';

import { Wrapper, FormWrapper } from '../login/login.style';
import { parseQueryString } from 'src/core/util/string';
import { postJSON } from 'src/core/util/loaders';

export const PasswordReset = ({ location, history }) => {
  const { m, c } = parseQueryString(location.search);
  return !m || !c ? (
    <Redirect to="/" />
  ) : (
    <Wrapper>
      <FormWrapper>
        <Typography component="h1" variant="h5">
          Reset Your Password
        </Typography>
        <Formik
          initialValues={{ password: '' }}
          validationSchema={Yup.object({
            password: Yup.string()
              .min(8, 'Must be 8 characters or more')
              .required('Required'),
          })}
          onSubmit={values => {
            postJSON(`${process.env.REACT_APP_API_ROOT}/password-reset/reset`, {
              email: m,
              code: c,
              password: values.password,
            })
              .then(d => {
                if (d.success) {
                  alert(
                    'Password reset successful. You can use your new password to login.',
                  );
                  history.push('/login');
                } else {
                  alert(
                    'An error occured. If you requested password reset multiple times, please make sure you are using the link in the latest email.',
                  );
                }
              })
              .catch(() =>
                alert('Looks like your password reset link is invalid.'),
              );
          }}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            errors,
            touched,
          }) => (
            <form onSubmit={handleSubmit} style={{ width: '100%' }}>
              <FormControl margin="normal" fullWidth>
                <InputLabel htmlFor="password">New Password</InputLabel>
                <Input
                  id="password"
                  type="password"
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormControl>
              <ErrorMessage name="password" />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                style={{ marginTop: 16 }}
              >
                Set New Password
              </Button>
            </form>
          )}
        </Formik>
      </FormWrapper>
    </Wrapper>
  );
};
