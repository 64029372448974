import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import { Formik } from 'formik';
import React from 'react';

export const BillingAddressForm = ({ companyInfo, submit }) => {
  const INITIAL = {
    ...companyInfo.address,
  };
  return (
    <Paper
      variant="outlined"
      style={{
        padding: '16px 32px 32px 32px',
        marginBottom: 32,
      }}
    >
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box padding="16px 0 32px 0">
              <Typography variant="h5">Billing Address</Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Formik
              initialValues={INITIAL}
              // validationSchema={Schema}
              onSubmit={(values, { setSubmitting }) => {
                submit(values);
                setTimeout(() => {
                  setSubmitting(false);
                }, 2000);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setValues,
              }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <TextField
                          label="Street # / Premise"
                          name="premise"
                          error={!!(errors.premise && touched.premise)}
                          helperText={touched.premise && errors.Prepremisemise}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.premise}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          name="street"
                          error={!!(errors.street && touched.street)}
                          helperText={touched.street && errors.street}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.street}
                          label="Street"
                          type="text"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          label="Unit # / Sub Premise"
                          name="subPremise"
                          error={!!(errors.subPremise && touched.subPremise)}
                          helperText={touched.subPremise && errors.subPremise}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.subPremise}
                          type="text"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          label="City / Locality"
                          name="locality"
                          error={!!(errors.locality && touched.locality)}
                          helperText={touched.locality && errors.locality}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.locality}
                          type="text"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          name="postalCode"
                          error={!!(errors.postalCode && touched.postalCode)}
                          helperText={touched.postalCode && errors.postalCode}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.postalCode}
                          label="Postal Code / Zip"
                          type="text"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={4}></Grid>
                      <Grid item xs={4}>
                        <TextField
                          name="country"
                          error={!!(errors.country && touched.country)}
                          helperText={touched.country && errors.country}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.country}
                          label="Country"
                          inputProps={{ maxLength: 3 }}
                          type="text"
                          fullWidth
                        />
                      </Grid>

                      <Grid item xs={4}>
                        <TextField
                          name="region"
                          error={!!(errors.region && touched.region)}
                          helperText={touched.region && errors.region}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.region}
                          label="Region"
                          type="text"
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                    <Box paddingTop="16px" display="flex">
                      <Button
                        disabled={isSubmitting}
                        type="submit"
                        color="primary"
                        style={{ marginLeft: 'auto' }}
                        variant="contained"
                      >
                        Save Changes
                      </Button>
                    </Box>
                    {isSubmitting && <CircularProgress size={24} />}
                  </form>
                );
              }}
            </Formik>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};
