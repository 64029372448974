import { createStructuredSelector } from "reselect";
import { path } from "ramda";

export const selectLoading = path(["billing", "company", "loading"]);
export const selectCompanies = path(["billing", "company", "companies"]);

export const companyConnector = createStructuredSelector({
  companies: selectCompanies,
  loading: selectLoading,
});
