import { compose, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import { withTheme } from '@material-ui/core';

import { mapDispatchers } from 'src/core/util/map-dispatchers';
import { eventsImportConnector } from './store/events-import.selector';
import { EventsImportComponent } from './events-import.component';
import {
  importEventsToAxescores,
  exportBookingsFromBATL,
  getBookingAccess,
  setBookingAccess,
  getAuthorizedVenues,
  deleteImportedBookingWithContest,
  getArenas,
} from './store/events-import.action';

const dispatchers = mapDispatchers({
  importEventsToAxescores,
  exportBookingsFromBATL,
  getBookingAccess,
  setBookingAccess,
  getAuthorizedVenues,
  deleteImportedBookingWithContest,
  getArenas,
});

const lifecycles = lifecycle({
  componentDidMount() {
    this.props.getAuthorizedVenues();
    this.props.getArenas();
  },
});

export const EventsImport = compose(
  connect(eventsImportConnector, dispatchers),
  withTheme,
  lifecycles,
)(EventsImportComponent);
