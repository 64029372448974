import { createAsyncActionTypes } from '../../../../util/async-action-type-creator';

export const GET_FEES_BY_MONTH = createAsyncActionTypes('GET_FEES_BY_MONTH');
export const GET_FEES_BY_COMPANY = createAsyncActionTypes(
  'GET_FEES_BY_COMPANY',
);
export const INSERT_FEE = createAsyncActionTypes('INSERT_FEE');
export const UPDATE_FEE = createAsyncActionTypes('UPDATE_FEE');
export const GET_FEE_DETAIL = createAsyncActionTypes('GET_FEE_DETAIL');
export const RESET_FEE_DETAIL = 'RESET_FEE_DETAIL';
export const RESET_FEES = 'RESET_FEES';
export const FEES_SET_MONTH = 'FEES_SET_MONTH';
export const FEES_SET_YEAR = 'FEES_SET_YEAR';
