import { createAsyncActionTypes } from 'src/core/util/async-action-type-creator';

export const EXPORT_BOOKINGS_FROM_SOURCE = createAsyncActionTypes(
  'EXPORT_BOOKINGS_FROM_SOURCE',
);
export const IMPORT_EVENTS_TO_AXESCORES = createAsyncActionTypes(
  'IMPORT_EVENTS_TO_AXESCORES',
);

export const GET_BOOKING_ACCESS = createAsyncActionTypes('GET_BOOKING_ACCESS');

export const SET_BOOKING_ACCESS = createAsyncActionTypes('SET_BOOKING_SOURCE');

export const GET_AUTHORIZED_VENUES = createAsyncActionTypes(
  'GET_AUTHORIZED_VENUES',
);

export const GET_ARENAS = createAsyncActionTypes('GET_ARENAS');
