import { createAsyncActionTypes } from '../../../../util/async-action-type-creator';

export const GET_BILLING_INFO = createAsyncActionTypes('GET_BILLING_INFO');
export const GET_COMPANY_FEES = createAsyncActionTypes('GET_COMPANY_FEES');
export const UPDATE_CREDIT_CARD = createAsyncActionTypes('UPDATE_CREDIT_CARD');
export const UPDATE_ADDRESS = createAsyncActionTypes('UPDATE_ADDRESS');
export const UPDATE_BILLING_CONTACT = createAsyncActionTypes(
  'UPDATE_BILLING_CONTACT',
);
export const CREATE_CREDIT_CARD = createAsyncActionTypes('CREATE_CREDIT_CARD');
export const CREATE_BILLING_CONTACT = createAsyncActionTypes(
  'CREATE_BILLING_CONTACT',
);
export const DELETE_BILLING_CONTACT = createAsyncActionTypes(
  'DELETE_BILLING_CONTACT',
);
export const DELETE_CREDIT_CARD = createAsyncActionTypes('DELETE_CREDIT_CARD');
