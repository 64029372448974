import React from 'react';

import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Button, CardActionArea } from '@material-ui/core';

export const ListLink = styled(Link)`
  display: ${(props) => props.display || 'inherit'};
  color: inherit;
  text-decoration: inherit;
  align-items: inherit;
  justify-content: inherit;
`;

export const ListHrefLink = styled.a`
  display: ${(props) => props.display || 'inherit'};
  color: inherit;
  text-decoration: inherit;
  align-items: inherit;
  justify-content: inherit;
`;

const AdapterLink = React.forwardRef((props, ref) => (
  <Link innerRef={ref} {...props} />
));

export const ButtonLink = (props) => (
  <Button component={AdapterLink} {...props} />
);

export const CardActionAreaLink = ({ children, ...props }) => (
  <CardActionArea component={AdapterLink} {...props}>
    {children}
  </CardActionArea>
);
