import React from 'react';
import { Route, Redirect } from 'react-router-dom';

export const ProtectedRouteComponent = ({
  component: Component,
  isLoggedIn,
  ...props
}) => (
  <Route
    {...props}
    render={props =>
      isLoggedIn === true ? <Component {...props} /> : <Redirect to="/login" />
    }
  />
);
