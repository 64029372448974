import React, { Fragment, useState } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { format } from 'date-fns';

export const ImportModal = ({
  open,
  selectedBookings,
  access,
  arenas,
  venues,
  venueID,
  callback = () => null,
  handleClose = () => null,
  importEvents = () => null,
}) => {
  const [bookings, setBookings] = useState(selectedBookings);
  const [importing, setImporting] = useState(false);

  const venue = venues.find((v) => v.ID === venueID);
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="md"
      aria-labelledby="Import Selected Bookings"
    >
      <DialogTitle id="form-dialog-title">Import Selected Bookings</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please select arena and side information for each booking.
        </DialogContentText>
        <TableContainer>
          <Table aria-label="Selected Bookings">
            <TableHead>
              <TableRow>
                <TableCell>Time</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Arena</TableCell>
                <TableCell>Side</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {bookings.map((b, i) => (
                <TableRow key={b.bookingID} hover>
                  <TableCell>
                    {format(new Date(b.startDatetime), 'hh:mmaaaaa') + 'm'}
                  </TableCell>
                  <TableCell>{b.organizerName || b.organizerEmail}</TableCell>
                  <TableCell>
                    <Select
                      value={b.ColourID === 0 ? b.ColourID : b.ColourID || -1}
                      onChange={(e) =>
                        setBookings(
                          Object.assign([], bookings, {
                            [i]: {
                              ...b,
                              ColourID: e.target.value,
                            },
                          }),
                        )
                      }
                    >
                      <MenuItem value={-1}>Select Arena</MenuItem>
                      {arenas.map((a) => (
                        <MenuItem key={a.ID} value={a.ID}>
                          {a.Name}
                        </MenuItem>
                      ))}
                    </Select>
                  </TableCell>
                  <TableCell>
                    <Select
                      value={b.SideID === 0 ? b.SideID : b.SideID || -1}
                      onChange={(e) =>
                        setBookings(
                          Object.assign([], bookings, {
                            [i]: {
                              ...b,
                              SideID: e.target.value,
                            },
                          }),
                        )
                      }
                    >
                      <MenuItem value={-1}>Select Side</MenuItem>
                      <MenuItem value={0}>Whole</MenuItem>
                      <MenuItem value={1}>Left</MenuItem>
                      <MenuItem value={2}>Right</MenuItem>
                    </Select>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Fragment>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disabled={importing}
            onClick={() => {
              if (
                bookings.some(
                  (b) =>
                    (!b.ColourID && b.ColourID !== 0) ||
                    (!b.SideID && b.SideID !== 0),
                )
              ) {
                return alert('Please pick all the arenas and sides');
              }
              setImporting(true);
              importEvents(
                bookings
                  .map((b) => ({ ...b, VenueName: venue.Name }))
                  .filter(
                    (b) =>
                      (b.SideID || b.SideID === 0) &&
                      (b.ColourID || b.ColourID === 0),
                  ),
              ).then(() => {
                callback().then(() => {
                  setImporting(false);
                  handleClose();
                });
              });
            }}
          >
            Import
          </Button>
        </Fragment>
      </DialogActions>
    </Dialog>
  );
};
