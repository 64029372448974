import { compose, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import { withTheme } from '@material-ui/core';

import { mapDispatchers } from 'src/core/util/map-dispatchers';
import { getCompanies, createCompany } from './store/companies.action';
import { companiesConnector } from './store/companies.selector';
import { CompaniesComponent } from './companies.component';
import { getConfig } from '../config/config.action';

const dispatchers = mapDispatchers({
  getCompanies,
  createCompany,
  getConfig,
});

const lifecycles = lifecycle({
  componentDidMount() {
    const { getCompanies, getConfig } = this.props;
    getConfig();
    getCompanies();
  },
});

export const Companies = compose(
  connect(companiesConnector, dispatchers),
  withTheme,
  lifecycles,
)(CompaniesComponent);
