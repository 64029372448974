import React from 'react';
import { Formik } from 'formik';
import { Redirect } from 'react-router-dom';

import {
  Typography,
  FormControl,
  InputLabel,
  Input,
  Button,
} from '@material-ui/core';

import { Wrapper, FormWrapper } from './login.style';

export const LoginComponent = ({
  user,
  token,
  isLoggedIn,
  loading,
  error,
  passwordResetRequested,
  login,
  requestPasswordReset,
  history,
}) => {
  return isLoggedIn ? (
    <Redirect to="/" />
  ) : (
    <Wrapper>
      <FormWrapper>
        <Typography component="h1" variant="h5">
          Log in
        </Typography>
        <Formik
          initialValues={{ email: '', password: '' }}
          onSubmit={(values) => {
            const { email, password } = values;
            login({ email, password }).then(() => {
              // history.push('/');
            });
          }}
        >
          {({ values, handleChange, handleBlur, handleSubmit }) => (
            <form onSubmit={handleSubmit} style={{ width: '100%' }}>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="email">Email Address</InputLabel>
                <Input
                  id="email"
                  type="email"
                  name="email"
                  value={values.email}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  onBlur={handleBlur}
                />
              </FormControl>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="password">Password</InputLabel>
                <Input
                  id="password"
                  type="password"
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormControl>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={loading}
                style={{ marginTop: 16 }}
              >
                Log in
              </Button>
              {error && (
                <Typography
                  variant="body2"
                  gutterBottom
                  color="error"
                  align="center"
                  style={{ marginTop: 16 }}
                >
                  {error.message}
                </Typography>
              )}
              {error && (
                <Button
                  style={{ marginTop: 16 }}
                  fullWidth
                  color="primary"
                  onClick={() =>
                    requestPasswordReset(values.email).then(() =>
                      alert(
                        "A password reset link is sent to your inbox. Please contact S&T if you don't see it in your inbox or spam folder",
                      ),
                    )
                  }
                >
                  Send Reset Link to {values.email}
                </Button>
              )}
            </form>
          )}
        </Formik>
      </FormWrapper>
    </Wrapper>
  );
};
