import React, { useState, Fragment } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Menu,
  MenuItem,
  Button,
  Box,
} from '@material-ui/core';
import { Logo } from '../logo.component';
import { AccountCircle } from '@material-ui/icons';
import { ButtonLink } from '../links/links.component';

const AccountMenu = ({ anchorEl, onClose, logout, history }) => (
  <Menu anchorEl={anchorEl} keepMounted open={!!anchorEl} onClose={onClose}>
    <MenuItem
      onClick={() => {
        onClose();
        history.push('/');
      }}
    >
      Profile
    </MenuItem>
    <MenuItem
      onClick={() => {
        logout();
        onClose();
        history.push('/login');
      }}
    >
      Logout
    </MenuItem>
  </Menu>
);

export const AppHeaderComponent = ({
  theme,
  isLoggedIn,
  user,
  logout,
  history,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  return (
    <AppBar position="fixed" style={{ zIndex: theme.zIndex.drawer + 1 }}>
      <Toolbar>
        <ButtonLink edge="start" to="/">
          <Logo />
        </ButtonLink>
        <Box style={{ marginLeft: 'auto' }}></Box>
        {isLoggedIn && (
          <Fragment>
            {/* <SearchBar
              employees={employees}
              getEmployees={getEmployees}
              onEmployeeClick={(id) => history.push(`/employee/${id}`)}
            /> */}
            <Button onClick={(e) => setAnchorEl(e.currentTarget)}>
              <Typography
                variant="button"
                style={{ color: '#fff', marginRight: 16 }}
              >
                {user.firstName} {user.lastName}
              </Typography>
              <AccountCircle style={{ color: '#fff' }} />
            </Button>
            <AccountMenu
              anchorEl={anchorEl}
              logout={logout}
              onClose={() => setAnchorEl(null)}
              history={history}
            />
          </Fragment>
        )}
      </Toolbar>
    </AppBar>
  );
};
