import { compose, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import { withTheme } from '@material-ui/core';

import { mapDispatchers } from 'src/core/util/map-dispatchers';
import { getConfig } from '../config/config.action';
import { CompanyDetailComponent } from './company-detail.component';
import {
  getCompany,
  getCompanyAccess,
  getCompanyVenues,
  resetCompanyDetail,
  updateCompany,
  updateCompanyVenue,
  createCompanyVenue,
  addAccess,
  removeAccess,
} from './store/company-detail.action';
import { companyDetailConnector } from './store/company-detail.selector';

const dispatchers = mapDispatchers({
  getCompany,
  getCompanyAccess,
  getCompanyVenues,
  getConfig,
  resetCompanyDetail,
  updateCompany,
  updateCompanyVenue,
  createCompanyVenue,
  addAccess,
  removeAccess,
});

const lifecycles = lifecycle({
  componentDidMount() {
    const {
      getCompany,
      getCompanyAccess,
      getCompanyVenues,
      getConfig,
      resetCompanyDetail,
      match,
    } = this.props;
    resetCompanyDetail();
    getConfig();
    getCompany(match.params.id);
    getCompanyAccess(match.params.id);
    getCompanyVenues(match.params.id);
  },
  componentWillUnmount() {
    this.props.resetCompanyDetail();
  },
});

export const CompanyDetail = compose(
  connect(companyDetailConnector, dispatchers),
  withTheme,
  lifecycles,
)(CompanyDetailComponent);
