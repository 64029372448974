import {
  LOGIN,
  REQUEST_PASSWORD_RESET,
  LOGOUT,
  RESET_LOGIN_FORM,
  SET_USER,
} from './login.constant';

const initialState = {
  isLoading: false,
  error: false,
  passwordResetRequested: false,
};

const initialAuthState = {
  user: null,
  token: null,
  isLoggedIn: false,
};

export const authReducer = (state = initialAuthState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case LOGIN.SUCCESS:
      return {
        token: payload.token,
        user: payload.user,
        isLoggedIn: true,
      };

    case SET_USER:
      return {
        ...state,
        user: payload,
      };
    case LOGIN.START:
    case LOGIN.ERROR:
    case REQUEST_PASSWORD_RESET.START:
    case LOGOUT:
    case RESET_LOGIN_FORM:
      return initialAuthState;

    default:
      return state;
  }
};

export const loginReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case LOGIN.START:
      return {
        ...initialState,
        isLoading: true,
      };

    case LOGIN.SUCCESS:
      return {
        isLoading: false,
      };

    case LOGIN.ERROR:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };

    case REQUEST_PASSWORD_RESET.START:
      return {
        ...initialState,
        passwordResetRequested: true,
      };

    case LOGOUT:
    case RESET_LOGIN_FORM:
      return initialState;

    default:
      return state;
  }
};
