import styled from 'styled-components';
import { Paper, withTheme } from '@material-ui/core';

export const Wrapper = styled.div`
  padding-top: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FormWrapper = withTheme(styled(Paper)`
  max-width: 400px;
  min-width: 300px;
  margin-top: ${({ theme }) => theme.spacing(8)};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(2)}px
    ${({ theme }) => theme.spacing(3)}px ${({ theme }) => theme.spacing(3)}px;
`);
