import { createStructuredSelector } from 'reselect';
import { path } from 'ramda';

export const selectLoading = path(['billing', 'fees', 'loading']);
export const selectSaving = path(['billing', 'fees', 'saving']);
export const selectError = path(['billing', 'fees', 'error']);
export const selectFeesByMonth = path(['billing', 'fees', 'feesByMonth']);
export const selectMonth = path(['billing', 'fees', 'month']);
export const selectYear = path(['billing', 'fees', 'year']);
export const selectSelectedFee = path(['billing', 'fees', 'selectedFee']);
export const feeConnector = createStructuredSelector({
  feesByMonth: selectFeesByMonth,
  selectedFee: selectSelectedFee,
  loading: selectLoading,
  saving: selectSaving,
  error: selectError,
  month: selectMonth,
  year: selectYear,
});
