import { GET_CONFIG } from './config.constant';

const initialState = {
  config: {},
  loading: false,
};

export const configReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case GET_CONFIG.SUCCESS:
      return {
        config: payload,
        loading: false,
      };

    case GET_CONFIG.START:
      return {
        ...state,
        loading: true,
      };

    default:
      return state;
  }
};
