import React from 'react';

export const contactsConfig = [
  {
    id: 'contactName',
    label: 'Name',
  },
  {
    id: 'email',
    label: 'Email',
  },
  {
    id: 'phone',
    label: 'Phone Number',
  },
];

export const mockContacts = [
  {
    Name: 'Ali Bardakci',
    Email: 'abardakci@batlgrounds.com',
    Phone: '+1 416 888 88 88',
  },
];

export const paymentsConfig = [
  {
    id: 'last4Digits',
    label: 'CC Number',
    format: (val) => `****-****-****-${val}`,
  },
  {
    id: 'firstName',
    label: 'Name on Card',
    format: (val, { firstName, lastName }) =>
      `${firstName || ''} ${lastName || ''}`,
  },
  {
    id: 'ExpiryMonth',
    label: 'Expiry Date',
    format: (val, { expiryYear, expiryMonth }) =>
      `${expiryMonth || ''} / ${expiryYear || ''}`,
  },
];

export const chargeConfig = [
  {
    id: 'Name',
    label: 'Name',
  },
  {
    id: 'Players',
    label: '# of Players',
  },
  {
    id: 'Amount',
    label: 'Charge Amount',
  },
];

export const payHistoryConfig = [
  {
    id: 'billingMonth',
    label: 'Date',
    format: (val, { feeType }) => (
      <span style={{ fontWeight: feeType ? 'normal' : 'bold' }}>
        {val.split('T')[0]}
      </span>
    ),
  },
  {
    id: 'feeType',
    label: 'Fee Type',
  },
  {
    id: 'totalAmount',
    label: 'Total Amount',
  },
  {
    id: 'details',
    label: 'Details',
  },
];
