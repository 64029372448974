import { GET_COMPANIES } from "./company.constant";

const initialState = {
  companies: [],
  loading: false,
  error: null,
};

export const companyReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case GET_COMPANIES.START:
      return {
        ...state,
        loading: true,
      };
    case GET_COMPANIES.SUCCESS:
      return {
        ...state,
        companies: payload,
        loading: false,
      };
    default:
      return state;
  }
};
