import React from 'react';
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from '@material-ui/core';
import { CardActionAreaLink } from 'src/components/links/links.component';

export const HomeComponent = ({ user }) => (
  <Box padding={2} overflow="hidden">
    <Grid container spacing={2}>
      <Grid item md={4} sm={6} xs={12}>
        <Card>
          <CardActionAreaLink to="/companies">
            <CardMedia
              style={{ height: 240 }}
              image="/images/companies.png"
              title="Companies"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                Companies
              </Typography>
              <Typography variant="body1">
                Manage companies, venues, staff and more
              </Typography>
            </CardContent>
          </CardActionAreaLink>
        </Card>
      </Grid>
      <Grid item md={4} sm={6} xs={12}>
        <Card>
          <CardActionAreaLink to="/billing/companies">
            <CardMedia
              style={{ height: 240, backgroundPosition: 'top' }}
              image="https://axescores.com/images/hero.png"
              title="Events"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                Billing
              </Typography>
              <Typography variant="body1">
                Manage payments for member companies
              </Typography>
            </CardContent>
          </CardActionAreaLink>
        </Card>
      </Grid>
    </Grid>
  </Box>
);
