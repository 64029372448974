import React, { Fragment, useRef } from 'react';
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
} from '@material-ui/core';
import { pathOr } from 'ramda';
import { TableActions, ActionPusher } from './table-base.style';
import { Description } from '@material-ui/icons';

export const TableBase = ({
  columns = [],
  rows = [],
  exportFileName,
  actions,
  onRowClick = () => null,
  idColumnIndex = -1,
  scroll = false,
}) => {
  const table = useRef(null);

  const toCsv = () => {
    const rows = [...table.current.querySelectorAll('tr')];
    // TODO: handle leading zeroes for id
    const csv = rows
      .map((row) => {
        const cols = row.querySelectorAll('td, th');
        return [...cols]
          .map((col, index) => {
            if (index === idColumnIndex) {
              return `="${col.innerText}"`;
            }
            return col.innerText.split(',').join('-');
          })
          .join(',');
      })
      .join('\n');

    const csvFile = new Blob([csv], { type: 'text/csv' });
    const link = document.createElement('a');
    link.download = exportFileName;
    link.href = window.URL.createObjectURL(csvFile);
    document.body.appendChild(link);
    link.click();
  };

  return (
    <Fragment>
      <TableActions>
        {actions}
        <ActionPusher />
        {exportFileName && (
          <Button
            color="primary"
            size="small"
            startIcon={<Description />}
            onClick={(e) => {
              toCsv();
            }}
          >
            Export As CSV
          </Button>
        )}
      </TableActions>
      <Paper width="100%" square variant="outlined">
        <TableContainer style={{ maxHeight: !scroll ? '80vh' : 'auto' }}>
          <Table stickyHeader ref={table}>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={column.style}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => {
                return (
                  <TableRow
                    hover
                    key={index}
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      onRowClick(row);
                    }}
                  >
                    {columns.map((column, index) => {
                      const value = pathOr('', column.id.split('.'), row);
                      return (
                        <TableCell
                          key={index}
                          align={column.align}
                          style={column.style}
                        >
                          {column.format ? column.format(value, row) : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Fragment>
  );
};
