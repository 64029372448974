import { path } from 'ramda';
import { createStructuredSelector, createSelector } from 'reselect';
import { startOfWeek, isToday, addDays, isSameDay, format } from 'date-fns';

import { selectLoggedInUser } from 'src/scenes/login/store/login.selector';

export const selectSchedule = path(['home', 'schedule']);
export const selectAcknowledge = path(['home', 'acknowledge']);

export const selectCalendar = createSelector(selectSchedule, schedule => {
  const weekStart = addDays(
    startOfWeek(
      schedule && schedule.length > 0
        ? new Date(schedule[0].StartTime)
        : new Date(),
    ),
    1,
  );

  const weeks = Array.from({ length: 4 }).map((week, wIndex) => ({
    index: wIndex,
    days: Array.from({ length: 7 }).map((day, dIndex) => {
      const d = addDays(weekStart, wIndex * 7 + dIndex);

      return {
        index: dIndex,
        date: d,
        today: isToday(d),
        display: format(d, 'MMM d'),
        events:
          !schedule || schedule.length === 0
            ? []
            : schedule
                .filter(s => isSameDay(d, new Date(s.StartTime)))
                .map(s => ({
                  id: s.ShiftID,
                  venue: s.Venue,
                  start: s.StartTime,
                  end: s.EndTime,
                  type: s.ShiftType,
                  startDisplay: format(new Date(s.StartTime), 'h:mm a'),
                  endDisplay: format(new Date(s.EndTime), 'h:mm a'),
                  startOnCall: s.StartOnCall,
                  endOnCall: s.EndOnCall,
                  swapOutId: s.SwapOutID,
                  swapOutStatusId: s.SwapOutStatusID,
                  auctionId1: s.AuctionID1,
                  auctionId2: s.AuctionID2,
                  swapInId: s.SwapInID,
                  swapInStatusId: s.SwapInStatusID,
                })),
      };
    }),
  }));

  return weeks;
});

export const homeConnector = createStructuredSelector({
  user: selectLoggedInUser,
  schedule: selectSchedule,
  acknowledge: selectAcknowledge,
  calendar: selectCalendar,
});
