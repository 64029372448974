import { path } from 'ramda';
import { createSelector, createStructuredSelector } from 'reselect';

export const selectVenues = createSelector(
  path(['eventsImport', 'venues']),
  (venues) => venues && venues.sort((a, b) => (a.Name > b.Name ? 1 : -1)),
);
export const selectArenas = path(['eventsImport', 'arenas']);
export const selectBookings = path(['eventsImport', 'bookings']);
export const selectBookingsLoading = path(['eventsImport', 'bookingsLoading']);
export const selectBookingsError = path(['eventsImport', 'bookingsError']);
export const selectBookingsImportLoading = path([
  'eventsImport',
  'bookingsImportLoading',
]);
export const selectBookingsImportError = path([
  'eventsImport',
  'bookingsImportError',
]);

export const selectEvents = createSelector(
  path(['eventsImport', 'events']),
  (events) =>
    events &&
    events.map((e) => ({
      ...e,
      id: e.BookingID,
    })),
);

export const selectBookingSources = path(['eventsImport', 'bookingSources']);
export const selectAccess = path(['eventsImport', 'access']);
export const selectAccessError = path(['eventsImport', 'accessError']);

export const selectTransformedBookings = createSelector(
  selectBookings,
  selectAccess,
  selectEvents,
  (bookings, access, events) =>
    bookings &&
    access &&
    bookings
      .filter((b) => {
        return !events.find((e) => e.BookingID === b.bookingID);
      })
      .map((b) => ({
        ...b,
        endDatetime:
          b.endDatetime === '0001-01-01T00:00:00' ? null : b.endDatetime,
        contestID: null,
        venueID: access.VenueID,
        id: b.bookingID,
        notes: `${b.groupSizeDisplay} (${b.expectedGuests})\n${
          b.adminNotes && `Admin: ${b.adminNotes}`
        }\n${b.adminNotes && `Customer: ${b.userNotes}`}`,
      })),
);

export const eventsImportConnector = createStructuredSelector({
  venues: selectVenues,
  arenas: selectArenas,
  bookings: selectTransformedBookings,
  bookingsLoading: selectBookingsLoading,
  bookingsError: selectBookingsError,
  bookingsImportLoading: selectBookingsImportLoading,
  bookingsImportError: selectBookingsImportError,
  events: selectEvents,
  bookingSources: selectBookingSources,
  access: selectAccess,
  accessError: selectAccessError,
});
