import { baseAssetUrl } from "../core/constants/url.constant";
const { REACT_APP_API_ROOT } = process.env;

export const buildUrl = (route, ...args) =>
  route
    .split("/")
    .map((path) => (path.startsWith(":") ? args.shift() : path))
    .join("/");

export const buildVintageUrl = (path) => `/redirect?r=${path}`;

export const buildAssetUrl = (path) => `${baseAssetUrl}${path}`;

export const buildPlayerPictureUrl = (playerId) =>
  `${baseAssetUrl}/pic/${playerId}`;

export const buildVenuePictureUrl = (clubId) =>
  `${baseAssetUrl}/pic/venue/${clubId}`;

export const buildOrgPictureUrl = (orgId) => `${baseAssetUrl}/pic/co/${orgId}`;

export const buildTeamProfilePictureURL = (teamId) =>
  `${REACT_APP_API_ROOT}/team/pic/${teamId}`;

export const buildPlayerPictureURL = buildPlayerPictureUrl;
