import { Formik } from 'formik';
import React from 'react';
// import * as Yup from 'yup';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import { Fragment } from 'react';

const INITIAL = {
  last4Digits: '',
  cardNumber: '',
  firstName: '',
  lastName: '',
  expiryYear: '',
  expiryMonth: '',
  postalCode: '',
};

export const PaymentInfoModal = ({
  open,
  handleClose,
  source,
  onDelete,
  submit = () => new Promise((r) => r(null)),
}) => {
  const isEdit = !!source;
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <Formik
        initialValues={source ? { ...INITIAL, ...source } : INITIAL}
        onSubmit={(values, { setSubmitting }) => {
          submit(values, isEdit).then(() => setSubmitting(false));
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setValues,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogTitle id="form-dialog-title">
                Create A New Billing Contact
              </DialogTitle>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    {isEdit ? (
                      <TextField
                        error={!!(errors.last4Digits && touched.last4Digits)}
                        helperText={touched.last4Digits && errors.last4Digits}
                        label="Card Number"
                        type="text"
                        fullWidth
                        name="CardNumber"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={`****-****-****-${values.last4Digits}`}
                        disabled
                      />
                    ) : (
                      <TextField
                        autoFocus
                        error={!!(errors.cardNumber && touched.cardNumber)}
                        helperText={touched.cardNumber && errors.cardNumber}
                        label="Card Number"
                        type="text"
                        fullWidth
                        name="cardNumber"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.cardNumber}
                      />
                    )}
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      error={!!(errors.expiryMonth && touched.expiryMonth)}
                      helperText={touched.expiryMonth && errors.expiryMonth}
                      label="Exp.Month"
                      type="text"
                      fullWidth
                      name="expiryMonth"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.expiryMonth}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      error={!!(errors.expiryYear && touched.expiryYear)}
                      helperText={touched.expiryYear && errors.expiryYear}
                      label="Exp. Year"
                      type="text"
                      fullWidth
                      name="expiryYear"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.expiryYear}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      error={
                        !!(errors.verificationCode && touched.verificationCode)
                      }
                      helperText={
                        touched.verificationCode && errors.verificationCode
                      }
                      label="cvv"
                      type="text"
                      fullWidth
                      name="verificationCode"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.verificationCode}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      error={!!(errors.firstName && touched.firstName)}
                      helperText={touched.firstName && errors.firstName}
                      label="First Name"
                      type="text"
                      fullWidth
                      name="firstName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.firstName}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      error={!!(errors.lastName && touched.lastName)}
                      helperText={touched.lastName && errors.lastName}
                      label="Last Name"
                      type="text"
                      fullWidth
                      name="lastName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.lastName}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      name="address.postalCode"
                      error={!!(errors.postalCode && touched.postalCode)}
                      helperText={touched.postalCode && errors.postalCode}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.address && values.address.postalCode}
                      label="Postal Code / Zip*"
                      type="text"
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                {!!source === true && (
                  <Button
                    onClick={() => {
                      if (
                        window.confirm(
                          'Are you sure you want to delete the credit card "' +
                            `****-****-****-${values.last4Digits}` +
                            '" from the system?',
                        )
                      ) {
                        onDelete(source.id);
                      }
                    }}
                    color="secondary"
                    variant="contained"
                    style={{ marginRight: 'auto' }}
                  >
                    Delete
                  </Button>
                )}
                {!isSubmitting ? (
                  <Fragment>
                    <Button onClick={handleClose} color="primary">
                      Cancel
                    </Button>
                    <Button
                      disabled={isSubmitting}
                      type="submit"
                      color="primary"
                    >
                      {!!source === true ? 'Update' : 'Submit'}
                    </Button>
                  </Fragment>
                ) : (
                  <Fragment>
                    <Typography variant="caption">
                      This might take a few seconds, please wait...
                    </Typography>
                    <CircularProgress size={24} />
                  </Fragment>
                )}
              </DialogActions>
            </form>
          );
        }}
      </Formik>
    </Dialog>
  );
};
