import { Formik } from 'formik';
import React from 'react';
// import * as Yup from 'yup';
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { Fragment } from 'react';

const INITIAL = {
  Name: '',
  Features: [1, 8, 16, 64, 4096],
  Street: '',
  Premise: '',
  SubPremise: '',
  PostalCode: '',
  Locality: '',
  SubRegion: '',
  Region: '',
  Country: '',
};

// const Schema = Yup.object().shape({
//   Name: Yup.string()
//     .min(2, 'Too Short! Min 2, Max 128.')
//     .max(128, 'Too Long! Min 2, Max 128.')
//     .required('Required.'),
//   Features: Yup.array().min(1, 'Select at least one.').required('Required.'),
//   Street: Yup.string()
//     .min(2, 'Too Short! Min 2, Max 128.')
//     .max(128, 'Too Long! Min 2, Max 128.')
//     .required('Required.'),
//   Premise: Yup.string().max(16, 'Too Long! Max 16.').required('Required.'),
//   SubPremise: Yup.string(),
//   PostalCode: Yup.string().max(16, 'Too Long! Max 16.').required('Required.'),
//   Locality: Yup.string().max(128, 'Too Long! Max 128.').required('Required.'),
//   SubRegion: Yup.string(),
//   Region: Yup.string(),
//   Country: Yup.string().required('Required.'),
// });

export const CreateCompany = ({
  type,
  open,
  loading,
  handleClose,
  features,
  initial,
  countries,
  regions,
  submit = () => null,
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <Formik
        initialValues={initial ? { ...INITIAL, ...initial } : INITIAL}
        onSubmit={(values, { setSubmitting }) => {
          submit(values).then(() => setSubmitting(false));
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setValues,
        }) => {
          const _regions = regions.filter((r) =>
            values.Country ? r.CountryID === values.Country : false,
          );

          if (!_regions.length && values.Region !== '') {
            setValues({ ...values, Region: '' });
          }

          return (
            <form onSubmit={handleSubmit}>
              <DialogTitle id="form-dialog-title">
                Create A New {type === 'venue' ? 'Venue' : 'Company'}
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  To add a new {type === 'venue' ? 'Venue' : 'Company'} to the
                  axescores system, please enter the
                  {type === 'venue' ? ' Venue' : ' Company'} name and enter the
                  address of the {type === 'venue' ? 'Venue' : 'Company'}.
                </DialogContentText>

                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      autoFocus
                      error={!!(errors.Name && touched.Name)}
                      helperText={touched.Name && errors.Name}
                      label={`${type === 'venue' ? 'Venue' : 'Company'} Name*`}
                      type="text"
                      fullWidth
                      name="Name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.Name}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    {features && (
                      <FormControl
                        style={{ width: '100%' }}
                        error={!!(errors.Features && touched.Features)}
                      >
                        <InputLabel>Select Features*</InputLabel>
                        <Select
                          multiple
                          fullWidth
                          name="Features"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.Features}
                          renderValue={(selected) => (
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                              {features
                                .filter((f) => selected.indexOf(f.ID) > -1)
                                .map((f, i) => (
                                  <span key={i}>
                                    {f.Name}
                                    {i === selected.length - 1 ? (
                                      ''
                                    ) : (
                                      <span>,&nbsp;</span>
                                    )}
                                  </span>
                                ))}
                            </div>
                          )}
                        >
                          {features.map((f) => (
                            <MenuItem key={f.ID} value={f.ID}>
                              <Checkbox
                                checked={values.Features.indexOf(f.ID) > -1}
                              />
                              <ListItemText primary={f.Name} />
                            </MenuItem>
                          ))}
                        </Select>
                        {touched.Features && errors.Features && (
                          <FormHelperText>{errors.Features}</FormHelperText>
                        )}
                      </FormControl>
                    )}
                  </Grid>
                  <Grid item xs={12} style={{ padding: '16px 6px 0 6px' }}>
                    <Typography variant="subtitle1">
                      {type === 'venue' ? 'Venue' : 'Company'} Address
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="Street # / Premise*"
                      name="Premise"
                      error={!!(errors.Premise && touched.Premise)}
                      helperText={touched.Premise && errors.Premise}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.Premise}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      name="Street"
                      error={!!(errors.Street && touched.Street)}
                      helperText={touched.Street && errors.Street}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.Street}
                      label="Street*"
                      type="text"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="Unit # / Sub Premise"
                      name="SubPremise"
                      error={!!(errors.SubPremise && touched.SubPremise)}
                      helperText={touched.SubPremise && errors.SubPremise}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.SubPremise}
                      type="text"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="City / Locality*"
                      name="Locality"
                      error={!!(errors.Locality && touched.Locality)}
                      helperText={touched.Locality && errors.Locality}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.Locality}
                      type="text"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      name="PostalCode"
                      error={!!(errors.PostalCode && touched.PostalCode)}
                      helperText={touched.PostalCode && errors.PostalCode}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.PostalCode}
                      label="Postal Code / Zip*"
                      type="text"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={4}></Grid>
                  <Grid item xs={4}>
                    <FormControl
                      style={{ width: '100%' }}
                      error={!!(errors.Country && touched.Country)}
                    >
                      <InputLabel>Country*</InputLabel>
                      <Select
                        name="Country"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.Country}
                      >
                        {countries.map((c) => (
                          <MenuItem key={c.ID} value={c.ID}>
                            {c.Name}
                          </MenuItem>
                        ))}
                      </Select>
                      {touched.Country && errors.Country && (
                        <FormHelperText>{errors.Country}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  {_regions.length !== 0 && (
                    <Grid item xs={4}>
                      <FormControl
                        style={{ width: '100%' }}
                        error={!!(errors.Region && touched.Region)}
                      >
                        <InputLabel>Region / Province</InputLabel>
                        <Select
                          name="Region"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.Region}
                        >
                          {_regions.map((r, i) => (
                            <MenuItem key={i} value={r.ID}>
                              {r.Name}
                            </MenuItem>
                          ))}
                        </Select>
                        {touched.Region && errors.Region && (
                          <FormHelperText>{errors.Region}</FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  )}
                </Grid>
              </DialogContent>
              <DialogActions>
                {initial !== true && initial !== false && (
                  <Button
                    onClick={() => {
                      if (
                        window.confirm(
                          'Are you sure you want to archive the venue "' +
                            values.Name +
                            '" from the system?',
                        )
                      ) {
                        handleClose();
                      }
                    }}
                    color="secondary"
                    variant="contained"
                    style={{ marginRight: 'auto' }}
                  >
                    Archive
                  </Button>
                )}
                {!isSubmitting ? (
                  <Fragment>
                    <Button onClick={handleClose} color="primary">
                      Cancel
                    </Button>
                    <Button
                      disabled={isSubmitting}
                      type="submit"
                      color="primary"
                    >
                      {initial !== true && initial !== false
                        ? 'Update'
                        : 'Submit'}
                    </Button>
                  </Fragment>
                ) : (
                  <Fragment>
                    <Typography variant="caption">
                      This might take a few seconds, please wait...
                    </Typography>
                    <CircularProgress size={24} />
                  </Fragment>
                )}
              </DialogActions>
            </form>
          );
        }}
      </Formik>
    </Dialog>
  );
};
