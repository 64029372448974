import { SET_TITLE } from "./home.constant";

const initialState = {
  title: "AxeScore Billing",
};

export const homeReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case SET_TITLE:
      return {
        ...state,
        title: payload,
      };
    default:
      return state;
  }
};
