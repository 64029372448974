import React, { Fragment, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import { CreateCompany } from './create-company.component';
import { getCompanyLogo } from 'src/core/util/string';

const classes = {};

export const CompaniesComponent = ({
  companies,
  countries,
  regions,
  features,
  history,
  createCompany,
}) => {
  const [search, setSearch] = useState('');
  const [createOpen, setCreateOpen] = useState(false);

  return (
    <Fragment>
      <Grid
        style={{ padding: 16, margin: 0, width: '100%' }}
        container
        spacing={2}
      >
        <Grid item xs={12}>
          <Box paddingBottom={6} paddingTop={2} display="flex">
            <TextField
              label="Filter Companies By Name"
              style={{ width: 300 }}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <Button
              variant="contained"
              color="primary"
              style={{ marginLeft: 'auto' }}
              size="small"
              onClick={() => setCreateOpen(true)}
            >
              Create A New Company
            </Button>
          </Box>
        </Grid>
        {companies
          .filter(
            (c) => c.Name.toLowerCase().indexOf(search.toLowerCase()) > -1,
          )
          .map((v, i) => (
            <Grid key={i} item xs={4}>
              <Card
                style={{ display: 'flex', height: '100%', cursor: 'pointer' }}
                onClick={() => history.push(`company/${v.ID}`)}
              >
                <div className={classes.details}>
                  <CardContent className={classes.content}>
                    <Typography component="h5" variant="h5">
                      {v.Name}
                    </Typography>
                    <Typography variant="subtitle1" color="textSecondary">
                      {v.SubDomain}
                    </Typography>
                  </CardContent>
                </div>
                <CardMedia
                  style={{
                    width: 100,
                    height: 'auto',
                    marginLeft: 'auto',
                    backgroundSize: '72px',
                  }}
                  image={getCompanyLogo(v.ID)}
                />
              </Card>
            </Grid>
          ))}
      </Grid>
      {features && (
        <CreateCompany
          open={createOpen}
          initial={false}
          handleClose={() => setCreateOpen(false)}
          submit={(values) => {
            const address = {
              Premise: values.Premise,
              Street: values.Street,
              SubPremise: values.SubPremise,
              Locality: values.Locality,
              PostalCode: values.PostalCode,
              Country: values.Country,
              Region: values.Region,
              SubRegion: values.SubRegion,
            };
            const payload = {
              ...values,
              ID: -1,
              AddressID: -1,
              Name: values.Name,
              SubDomain: values.SubDomain,
              Descrip: values.Descrip,
              Features: values.Features.reduce((acc, item) => acc + item, 0),
              Venues: [
                {
                  ID: -1,
                  Name: values.Name,
                  Address: address,
                },
              ],
              Address: address,
            };
            createCompany(payload).then((r) => {
              if (r instanceof Error) {
                return alert(r);
              }
              alert('Company created!');
              setCreateOpen(false);
            });
          }}
          features={features}
          countries={countries}
          regions={regions}
        />
      )}
    </Fragment>
  );
};
