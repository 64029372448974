import { compose } from 'recompose';
import { connect } from 'react-redux';

import { mapDispatchers } from 'src/core/util/map-dispatchers';
import { loginConnector } from './store/login.selector';

import { LoginComponent } from './login.component';
import { login, requestPasswordReset, reset } from './store/login.action';

const dispatchers = mapDispatchers({
  login,
  requestPasswordReset,
  reset,
});

export const Login = compose(connect(loginConnector, dispatchers))(
  LoginComponent,
);
