import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import { cols } from './monthly-fees.config';
import { useDispatch } from 'react-redux';
import {
  getFeesByMonth,
  setMonth,
  setYear,
  updateRollupFees,
  getFeeDetails,
  resetFeeDetails,
  updateFee,
} from 'src/AxeScores.APIConsumer/src/store/billing/fee/store/fee.action';
import { useSelector } from 'react-redux';
import { feeConnector } from 'src/AxeScores.APIConsumer/src/store/billing/fee/store/fee.selector';
import { getCompanies } from 'src/AxeScores.APIConsumer/src/store/billing/company/store/company.action';
import { companyConnector } from 'src/AxeScores.APIConsumer/src/store/billing/company/store/company.selector';
import { useHistory } from 'react-router-dom';

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
export const MonthlyFees = () => {
  const thisYear = new Date().getFullYear();

  const [selectedPayments, setSelectedPayments] = useState([]);
  const [rollupID, setRollupID] = useState(null);
  const d = useDispatch();
  const history = useHistory();

  const getMonth = () => `${year}-${String(month + 1).padStart(2, '0')}-01`;

  const getFees = () => d(getFeesByMonth(getMonth()));

  useEffect(() => {
    d(getCompanies());
    getFees();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { feesByMonth, loading, year, month, selectedFee } =
    useSelector(feeConnector);
  const { companies } = useSelector(companyConnector);
  console.log({ selectedFee });
  return (
    <Grid
      style={{ padding: 16, margin: 0, width: '100%' }}
      container
      spacing={2}
    >
      <Grid item xs={12}>
        <Box paddingBottom={2} paddingTop={2} display="flex">
          <FormControl style={{ marginRight: 32 }}>
            <InputLabel id="year-select-label">Year</InputLabel>
            <Select
              labelId="year-select-label"
              id="year-select"
              value={year}
              onChange={(e) => {
                d(setYear(e.target.value));
              }}
            >
              {Array.from({ length: 5 }).map((m, i) => (
                <MenuItem key={i} value={thisYear - i}>
                  {thisYear - i}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl style={{ marginRight: 32 }}>
            <InputLabel id="month-select-label">Month</InputLabel>
            <Select
              labelId="month-select-label"
              id="month-select"
              value={month}
              onChange={(e) => {
                d(setMonth(e.target.value));
              }}
            >
              {monthNames.map((m, i) => (
                <MenuItem key={i} value={i}>
                  {m}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <Button
              onClick={() => getFees()}
              color="primary"
              size="large"
              variant="contained"
            >
              GO
            </Button>
          </FormControl>
          {loading && (
            <CircularProgress
              style={{ marginLeft: 32, marginTop: 12 }}
              size={24}
            />
          )}
          <FormControl style={{ marginLeft: 'auto' }}>
            <InputLabel id="company-select-label">Go To Company</InputLabel>
            <Select
              labelId="company-select-label"
              id="company-select"
              value={-1}
              style={{ minWidth: 200 }}
              onChange={(e) => {
                if (e.target.value === -1) {
                  return;
                }
                history.push(`/billing/company/${e.target.value}`);
              }}
            >
              <MenuItem key={-1} value={-1}>
                Select...
              </MenuItem>
              {companies &&
                companies.map(({ id, name }) => (
                  <MenuItem key={id} value={id}>
                    {name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5">
          Fees for the month of {monthNames[month]}
        </Typography>
        <Paper>
          <Box height={'calc(100vh - 290px)'}>
            <DataGrid
              rows={feesByMonth}
              columns={cols(getFeeDetails, d, setRollupID)}
              checkboxSelection
              disableSelectionOnClick
              disableColumnMenu
              selectionModel={selectedPayments}
              onSelectionModelChange={(m) => setSelectedPayments(m)}
              size="small"
            />

            {selectedPayments.length > 0 && (
              <>
                <Button
                  color="primary"
                  variant="contained"
                  style={{ marginTop: -72, marginLeft: 8 }}
                  onClick={() => {
                    const payload = feesByMonth
                      .filter((f) => selectedPayments.includes(f.id))
                      .map((f) => ({
                        ...f,
                        approved: true,
                      }));
                    d(updateRollupFees(payload, getMonth()));
                  }}
                >
                  Approve {selectedPayments.length} items
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  style={{ marginTop: -72, marginLeft: 8 }}
                  onClick={() => {
                    const payload = feesByMonth
                      .filter((f) => selectedPayments.includes(f.id))
                      .map((f) => ({
                        ...f,
                        approved: false,
                      }));
                    d(updateRollupFees(payload, getMonth()));
                  }}
                >
                  Disapprove {selectedPayments.length} items
                </Button>
              </>
            )}
            {/* // <Paper>
              //   {JSON.stringify(selectedFee)}
              //   <button onClick={() => d(resetFeeDetails())}>close</button>
              // </Paper> */}
            <Dialog
              open={!!selectedFee}
              onClose={() => {
                d(resetFeeDetails());
              }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{'Fee Details'}</DialogTitle>
              <DialogContent>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Description</TableCell>
                      <TableCell align="right">Quantity</TableCell>
                      <TableCell align="right">Total Amount</TableCell>
                      <TableCell align="right">Approved</TableCell>
                      <TableCell align="right">Paid</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedFee &&
                      selectedFee.map((row) => (
                        <TableRow key={row.id}>
                          <TableCell component="th" scope="row">
                            {row.descrip}
                          </TableCell>
                          <TableCell align="right">{row.quantity}</TableCell>
                          <TableCell align="right">{row.totalAmount}</TableCell>
                          <TableCell align="right">
                            <Checkbox
                              checked={row.approved}
                              onChange={() => {
                                if (loading) return;
                                const payload = {
                                  ...row,
                                  approved: !row.approved,
                                  rollupID: rollupID,
                                };
                                d(updateFee([payload], getMonth()));
                              }}
                            />
                          </TableCell>
                          <TableCell align="right">
                            <Checkbox
                              checked={row.paid}
                              onChange={() => {
                                if (loading) return;
                                const payload = {
                                  ...row,
                                  paid: !row.paid,
                                  rollupID: rollupID,
                                };
                                d(updateFee([payload]));
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => d(resetFeeDetails())}
                  color="primary"
                  autoFocus
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};
