import styled from 'styled-components';

export const TableActions = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 0;
`;

export const ActionPusher = styled.div`
  margin-left: auto;
`;
