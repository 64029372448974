import { getJSON } from 'src/core/util/loaders';
import { GET_CONFIG } from './config.constant';

const getConfigSuccess = (payload) => ({
  type: GET_CONFIG.SUCCESS,
  payload,
});

// const getVenuesError = (error) => ({
//   type: GET_VENUES.ERROR,
//   payload: error,
// });

export const getConfig = () => (dispatch) => {
  const request = getJSON(`${process.env.REACT_APP_API_ROOT}/iatf/admin/config`)
    .then((payload) => {
      return dispatch(getConfigSuccess(payload));
    })
    .catch((error) => {
      return console.log(error);
    });

  dispatch({
    type: GET_CONFIG.START,
    payload: request,
  });

  return request;
};
