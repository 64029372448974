import React from 'react';
import { MainWrapper } from './main.style';
import { Toolbar } from '@material-ui/core';

export const MainComponent = ({ children, isLoggedIn }) => (
  <MainWrapper isLoggedIn={isLoggedIn}>
    <Toolbar />
    {children}
  </MainWrapper>
);
