import { createTheme } from '@material-ui/core/styles';

export const theme = createTheme({
  layout: {
    drawerWidth: '220px',
    appBarHeight: '48px',
  },
  palette: {
    primary: {
      main: '#02602f',
    },
    secondary: {
      main: '#e85555',
    },
  },
});
