import {
  Avatar,
  CircularProgress,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import {
  AttachMoneyRounded,
  Business,
  CreditCard,
  Face,
} from '@material-ui/icons';

import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  createBillingContact,
  createCreditCard,
  deleteBillingContact,
  deleteCreditCard,
  getBillingInfo,
  updateAddress,
  updateBillingContact,
  updateCreditCard,
} from 'src/AxeScores.APIConsumer/src/store/billing/billing-info/store/billing-info.action';
import { billingInfoConnector } from 'src/AxeScores.APIConsumer/src/store/billing/billing-info/store/billing-info.selector';
import { getCompanyLogo } from 'src/core/util/string';
import {
  contactsConfig,
  payHistoryConfig,
  paymentsConfig,
} from './company-detail.config';
import { BillingAddressForm } from './components/form-billing-address.component';
import { BillingContactModal } from './components/modal-billing-contact.component';
import { PastChargesModal } from './components/modal-past-charge.component';
import { PaymentInfoModal } from './components/modal-payment-info.component';
import { TableBillingContact } from './components/table-billing-contact.component';
import { TablePastCharges } from './components/table-past-charges.component';
import { TablePaymentInfo } from './components/table-payment-info.component';

export const BillingCompanyDetail = ({ match }) => {
  const [contactModal, setContactModal] = useState(false);
  const [selectedContact, setSelectedContact] = useState(null);

  const [paymentModal, setPaymentModal] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState(null);

  const [historyModal, setHistoryModal] = useState(false);
  const [selectedHistory, setSelectedHistory] = useState(null);

  const d = useDispatch();
  const params = useParams();
  const getInfo = () => d(getBillingInfo(params.id));

  const { billingInfo, fees, loading } = useSelector(billingInfoConnector);
  const { companyInfo, billingContact, creditCards } = billingInfo || {};

  useEffect(() => {
    getInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const groupedFees = [];

  fees.sort((a, b) => {
    const aDate = new Date(a.billingMonth);
    const bDate = new Date(b.billingMonth);

    return bDate - aDate;
  });

  fees.forEach((element, index) => {
    //we check if it is the first element to compare or if the Billing month is unique
    if (index === 0 || element.billingMonth !== fees[index - 1].billingMonth) {
      groupedFees.push({
        billingMonth: element.billingMonth,
      });
      groupedFees.push(element);
    } else {
      groupedFees.push(element);
    }
  });

  return (
    billingInfo && (
      <Fragment>
        <Grid
          style={{ padding: 16, margin: 0, width: '100%' }}
          container
          spacing={2}
        >
          <Grid item xs={3}>
            <List
              component="nav"
              aria-label="main mailbox folders"
              style={{
                backgroundColor: '#fff',
                position: 'sticky',
                zIndex: 999,
                top: 90,
              }}
            >
              <ListItem alignItems="center">
                <ListItemAvatar>
                  <Avatar
                    alt={companyInfo.companyName}
                    src={getCompanyLogo(companyInfo.axeScoresCompanyID)}
                  />
                </ListItemAvatar>
                <ListItemText primary={companyInfo.companyName} />
                {loading && <CircularProgress size={24} />}
              </ListItem>
              <Divider style={{ margin: '12px 0' }} />
              <ListItem
                button
                onClick={(e) => {
                  window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                }}
              >
                <ListItemIcon>
                  <Business />
                </ListItemIcon>
                <ListItemText primary="Billing Address" />
              </ListItem>

              <ListItem
                button
                onClick={(e) => {
                  const el = document.getElementById('billing-contacts');
                  window.scrollTo({
                    top: el.offsetTop - 90,
                    left: 0,
                    behavior: 'smooth',
                  });
                }}
              >
                <ListItemIcon>
                  <Face />
                </ListItemIcon>
                <ListItemText primary="Billing Contacts" />
              </ListItem>
              <ListItem
                button
                onClick={(e) => {
                  const el = document.getElementById('payment-sources');
                  window.scrollTo({
                    top: el.offsetTop - 90,
                    left: 0,
                    behavior: 'smooth',
                  });
                }}
              >
                <ListItemIcon>
                  <CreditCard />
                </ListItemIcon>
                <ListItemText primary="Credit Cards" />
              </ListItem>
              <ListItem
                button
                onClick={(e) => {
                  const el = document.getElementById('past-charges');
                  window.scrollTo({
                    top: el.offsetTop - 90,
                    left: 0,
                    behavior: 'smooth',
                  });
                }}
              >
                <ListItemIcon>
                  <AttachMoneyRounded />
                </ListItemIcon>
                <ListItemText primary="Past Charges" />
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={9}>
            <BillingAddressForm
              companyInfo={companyInfo}
              submit={(address) => d(updateAddress(address, params.id))}
            />
            {/* Billing Contacts */}
            <TableBillingContact
              contactsConfig={contactsConfig}
              setContactModal={setContactModal}
              setSelectedContact={setSelectedContact}
              contacts={billingContact}
            />
            {/* Payment Sources */}
            <TablePaymentInfo
              setPaymentModal={setPaymentModal}
              setSelectedPayment={setSelectedPayment}
              paymentsConfig={paymentsConfig}
              sources={creditCards}
            />

            {/* PAST CHARGES */}
            <TablePastCharges
              config={payHistoryConfig}
              charges={groupedFees}
              setSelectedHistory={setSelectedHistory}
              setHistoryModal={setHistoryModal}
            />
          </Grid>
        </Grid>
        <BillingContactModal
          open={contactModal}
          contact={selectedContact}
          handleClose={() => {
            setContactModal(false);
            setSelectedContact(null);
          }}
          onDelete={(bcid) => {
            setContactModal(false);
            setSelectedContact(null);
            d(deleteBillingContact(bcid, params.id));
          }}
          submit={(contact, isEdit) => {
            setContactModal(false);
            setSelectedContact(null);
            if (isEdit) {
              d(updateBillingContact(contact, params.id));
            } else {
              d(createBillingContact(contact, params.id));
            }
          }}
        />
        <PaymentInfoModal
          open={paymentModal}
          source={selectedPayment}
          handleClose={() => {
            setPaymentModal(false);
            setSelectedPayment(null);
          }}
          onDelete={(ccid) => {
            setPaymentModal(false);
            setSelectedPayment(null);
            d(deleteCreditCard(ccid, params.id));
          }}
          submit={(cc, isEdit) => {
            setPaymentModal(false);
            setSelectedPayment(null);
            if (isEdit) {
              d(updateCreditCard(cc, params.id));
            } else {
              d(createCreditCard(cc, params.id));
            }
          }}
        />
        {/* <PastChargesModal
          open={historyModal}
          history={selectedHistory}
          handleClose={() => {
            setHistoryModal(false);
            setSelectedHistory(null);
          }}
        /> */}
      </Fragment>
    )
  );
};
