import { keys } from 'ramda';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { rootReducer } from './reducer';

const isDevEnv = process.env.NODE_ENV === 'development';

const colors = {
  default: '#383838',
  clear_: '#7C394B',
  _error: '#E33231',
  _success: '#4CAF50',
  _start: '#03A9F4',
  undefined: '#8ADBDE',
  gigya: '#B25FD6',
  redirect: '#12505F',
};
const mapColor = () => {
  const colorCache = {};
  return title => {
    if (colorCache[title]) return colorCache[title];
    let color;
    keys(colors).forEach(key => {
      if (!color && title.toLowerCase().match(key)) {
        color = colors[key];
        colorCache[title] = color;
      }
    });
    return color ? color : colors.default;
  };
};

function _getMiddleware() {
  let middleware = [thunk];

  if (isDevEnv) {
    const cachedColorMapper = mapColor();
    const logger = createLogger({
      collapsed: true,
      duration: true,
      colors: {
        title: action => action.type && cachedColorMapper(action.type),
        prevState: () => '#E33231',
        action: () => '#03A9F4',
        nextState: () => '#4CAF50',
        error: () => '#E33231',
      },
    });
    middleware = [...middleware, logger];
  }

  return applyMiddleware(...middleware);
}

function _getEnhancers() {
  let enhancers = [];

  if (isDevEnv && window.__REDUX_DEVTOOLS_EXTENSION__) {
    enhancers = [...enhancers, window.__REDUX_DEVTOOLS_EXTENSION__()];
  }

  return enhancers;
}

function _enableHotLoader(store) {
  if (isDevEnv && module.hot) {
    module.hot.accept('./reducer', () => {
      const nextRootReducer = require('./reducer');
      store.replaceReducer(nextRootReducer);
    });
  }
}

const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['auth', 'persist'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const configureStore = initialState => {
  const store = compose(_getMiddleware(), ..._getEnhancers())(createStore)(
    persistedReducer,
    initialState,
  );
  const persistor = persistStore(store);
  _enableHotLoader(store);
  return { store, persistor };
};
