import {
  GET_FEES_BY_MONTH,
  GET_FEE_DETAIL,
  INSERT_FEE,
  UPDATE_FEE,
  RESET_FEES,
  FEES_SET_MONTH,
  FEES_SET_YEAR,
  RESET_FEE_DETAIL,
} from './fee.constant';

const initialState = {
  month: new Date().getMonth(),
  year: new Date().getFullYear(),
  feesByMonth: [],
  selectedFee: null,
  loading: false,
  saving: false,
  error: null,
};

export const feeReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case RESET_FEES:
      return {
        ...initialState,
      };
    case GET_FEES_BY_MONTH.START:
      return {
        ...state,
        loading: true,
      };
    case GET_FEES_BY_MONTH.SUCCESS:
      return {
        ...state,
        feesByMonth: payload,
        loading: false,
      };
    case GET_FEE_DETAIL.START:
      return {
        ...state,
        loading: true,
      };
    case GET_FEE_DETAIL.SUCCESS:
      return {
        ...state,
        selectedFee: payload,
        loading: false,
      };

    case RESET_FEE_DETAIL:
      return {
        ...state,
        selectedFee: null,
      };
    case FEES_SET_MONTH:
      return {
        ...state,
        month: payload,
      };
    case FEES_SET_YEAR:
      return {
        ...state,
        year: payload,
      };

    default:
      return state;
  }
};
