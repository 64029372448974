import { Box, Button, Paper, Typography } from '@material-ui/core';
import React from 'react';
import { TableBase } from 'src/components/table/table-base.component';

export const TableBillingContact = ({
  contactsConfig,
  setContactModal,
  setSelectedContact,
  contacts,
}) => (
  <Paper
    variant="outlined"
    style={{
      padding: '16px 32px 32px 32px',
      marginBottom: 32,
    }}
    id="billing-contacts"
  >
    <Box padding="16px 0 32px 0">
      <Box display="flex">
        <Typography variant="h5">Billing Contacts</Typography>
        <Button
          color="primary"
          style={{ marginLeft: 'auto' }}
          variant="contained"
          size="small"
          onClick={() => setContactModal(true)}
        >
          Add New
        </Button>
      </Box>
      <TableBase
        rows={contacts}
        columns={contactsConfig}
        scroll
        onRowClick={(row) => {
          setSelectedContact(row);
          setContactModal(true);
        }}
      />
    </Box>
  </Paper>
);
