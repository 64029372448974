import { createStructuredSelector } from 'reselect';

import {
  selectLoggedInUser,
  selectIsLoggedIn,
} from 'src/scenes/login/store/login.selector';

export const appHeeaderConnector = createStructuredSelector({
  user: selectLoggedInUser,
  isLoggedIn: selectIsLoggedIn,
});
