import { combineReducers } from 'redux';
import { loginReducer, authReducer } from './scenes/login/store/login.reducer';
import { homeReducer } from './scenes/home/store/home.reducer';
import { companiesReducer } from './scenes/companies/store/companies.reducer';
import { configReducer } from './scenes/config/config.reducer';
import { companyDetailReducer } from './scenes/company-detail/store/company-detail.reducer';
import { eventsImportReducer } from './scenes/events-import/store/events-import.reducer';
import { billingReducer } from 'src/AxeScores.APIConsumer/src/store/billing/reducer';

export const rootReducer = combineReducers({
  auth: authReducer,
  home: homeReducer,
  login: loginReducer,
  config: configReducer,
  companies: companiesReducer,
  eventsImport: eventsImportReducer,
  companyDetail: companyDetailReducer,
  billing: billingReducer,
});
