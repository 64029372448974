import { buildUrl } from '../../../../util/url.util';
import { endpoints } from '../../../../core/constants/url.constant';
import {
  CREATE_CREDIT_CARD,
  CREATE_BILLING_CONTACT,
  GET_BILLING_INFO,
  GET_COMPANY_FEES,
  UPDATE_BILLING_CONTACT,
  UPDATE_CREDIT_CARD,
  DELETE_BILLING_CONTACT,
  DELETE_CREDIT_CARD,
  UPDATE_ADDRESS,
} from './billing-info.constant';
import {
  deleteJSON,
  getJSON,
  postJSON,
  putJSON,
} from '../../../../util/loaders';

const getBillingInfoSuccess = (payload) => ({
  type: GET_BILLING_INFO.SUCCESS,
  payload,
});

const getBillingInfoError = (error) => ({
  type: GET_BILLING_INFO.ERROR,
  payload: error,
});

export const getBillingInfo = (companyId) => (dispatch) => {
  const request1 = getJSON(
    buildUrl(endpoints.billing.billingInfo.getInfo, companyId),
    {},
    {},
  );
  const request2 = getJSON(
    buildUrl(endpoints.billing.billingInfo.getFees, companyId),
    {},
    {},
  );
  const request = Promise.all([request1, request2])
    .then((res) => {
      return dispatch(getBillingInfoSuccess(res));
    })
    .catch((error) => {
      return dispatch(getBillingInfoError(error));
    });

  return dispatch({
    type: GET_BILLING_INFO.START,
    payload: request,
  });
};

const updateAddressError = (error) => ({
  type: UPDATE_ADDRESS.ERROR,
  payload: error,
});

export const updateAddress = (address, companyId) => (dispatch) => {
  const request = putJSON(endpoints.billing.billingInfo.updateAddress, address)
    .then(() => {
      return dispatch(getBillingInfo(companyId));
    })
    .catch((error) => {
      return dispatch(updateAddressError(error));
    });

  return dispatch({
    type: UPDATE_ADDRESS.START,
    payload: request,
  });
};

const updateBillingContactError = (error) => ({
  type: UPDATE_BILLING_CONTACT.ERROR,
  payload: error,
});

export const updateBillingContact = (contact, companyId) => (dispatch) => {
  const request = putJSON(endpoints.billing.billingInfo.updateContact, contact)
    .then(() => {
      return dispatch(getBillingInfo(companyId));
    })
    .catch((error) => {
      return dispatch(updateBillingContactError(error));
    });

  return dispatch({
    type: UPDATE_BILLING_CONTACT.START,
    payload: request,
  });
};

const createBillingContactError = (error) => ({
  type: CREATE_BILLING_CONTACT.ERROR,
  payload: error,
});

export const createBillingContact = (contact, companyId) => (dispatch) => {
  const request = postJSON(
    buildUrl(endpoints.billing.billingInfo.createContact, companyId),
    contact,
  )
    .then(() => {
      return dispatch(getBillingInfo(companyId));
    })
    .catch((error) => {
      return dispatch(createBillingContactError(error));
    });

  return dispatch({
    type: CREATE_BILLING_CONTACT.START,
    payload: request,
  });
};

export const deleteBillingContact = (bcid, companyId) => (dispatch) => {
  const request = deleteJSON(
    buildUrl(endpoints.billing.billingInfo.deleteContact, bcid),
  )
    .then((res) => {
      return dispatch(getBillingInfo(companyId));
    })
    .catch((error) => {
      return dispatch(createBillingContactError(error));
    });

  return dispatch({
    type: DELETE_BILLING_CONTACT.START,
    payload: request,
  });
};

const updateCreditCardError = (error) => ({
  type: UPDATE_CREDIT_CARD.ERROR,
  payload: error,
});

export const updateCreditCard = (cc, companyId) => (dispatch) => {
  const ccUpdate = putJSON(endpoints.billing.billingInfo.updateCC, cc);
  const addressUpdate = putJSON(
    endpoints.billing.billingInfo.updateAddress,
    cc.address,
  );
  const request = Promise.all([ccUpdate, addressUpdate])
    .then((res) => {
      return dispatch(getBillingInfo(companyId));
    })
    .catch((error) => {
      return dispatch(updateCreditCardError(error));
    });

  return dispatch({
    type: UPDATE_CREDIT_CARD.START,
    payload: request,
  });
};

const createCreditCardError = (error) => ({
  type: CREATE_CREDIT_CARD.ERROR,
  payload: error,
});

export const createCreditCard = (cc, companyId) => (dispatch) => {
  const request = postJSON(
    buildUrl(endpoints.billing.billingInfo.createCC, companyId),
    cc,
  )
    .then((res) => {
      return dispatch(getBillingInfo(companyId));
    })
    .catch((error) => {
      return dispatch(createCreditCardError(error));
    });

  return dispatch({
    type: CREATE_CREDIT_CARD.START,
    payload: request,
  });
};

export const deleteCreditCard = (ccid, companyId) => (dispatch) => {
  const request = deleteJSON(
    buildUrl(endpoints.billing.billingInfo.deleteCC, ccid),
  )
    .then((res) => {
      return dispatch(getBillingInfo(companyId));
    })
    .catch((error) => {
      return dispatch(createCreditCardError(error));
    });

  return dispatch({
    type: DELETE_CREDIT_CARD.START,
    payload: request,
  });
};
