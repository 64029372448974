import { deleteJSON, getJSON, postJSON, putJSON } from 'src/core/util/loaders';
import {
  GET_COMPANY,
  UPDATE_COMPANY,
  GET_COMPANY_ACCESS,
  GET_COMPANY_VENUES,
  RESET_COMPANY_DETAIL,
} from './company-detail.constant';

const getCompanySuccess = (payload) => ({
  type: GET_COMPANY.SUCCESS,
  payload,
});

export const getCompany = (id) => (dispatch) => {
  const request = getJSON(
    `${process.env.REACT_APP_API_ROOT}/iatf/admin/company/${id}`,
  )
    .then((payload) => {
      return dispatch(getCompanySuccess(payload));
    })
    .catch((error) => {
      console.log(error);
      return error;
    });

  dispatch({
    type: GET_COMPANY.START,
    payload: request,
  });

  return request;
};

export const updateCompany = (id, payload) => (dispatch) => {
  const request = putJSON(
    `${process.env.REACT_APP_API_ROOT}/iatf/admin/company/${id}`,
    payload,
  )
    .then((payload) => {
      return dispatch(getCompany(id));
    })
    .catch((error) => {
      console.log(error);
      return error;
    });

  dispatch({
    type: UPDATE_COMPANY.START,
    payload: request,
  });

  return request;
};

export const updateCompanyVenue = (payload) => (dispatch) => {
  const request = putJSON(
    `${process.env.REACT_APP_API_ROOT}/iatf/admin/venue/${payload.ID}`,
    payload,
  )
    .then((response) => {
      return dispatch(getCompanyVenues(payload.CompanyID));
    })
    .catch((error) => {
      console.log(error);
      return error;
    });

  dispatch({
    type: UPDATE_COMPANY.START,
    payload: request,
  });

  return request;
};

export const createCompanyVenue = (payload) => (dispatch) => {
  const request = postJSON(
    `${process.env.REACT_APP_API_ROOT}/iatf/admin/company/venue`,
    payload,
  )
    .then((response) => {
      return dispatch(getCompanyVenues(payload.CompanyID));
    })
    .catch((error) => {
      console.log(error);
      return error;
    });

  dispatch({
    type: UPDATE_COMPANY.START,
    payload: request,
  });

  return request;
};

const getCompanyAccessSuccess = (payload) => ({
  type: GET_COMPANY_ACCESS.SUCCESS,
  payload,
});

export const getCompanyAccess = (id) => (dispatch) => {
  const request = getJSON(
    `${process.env.REACT_APP_API_ROOT}/iatf/admin/company/${id}/access`,
  )
    .then((payload) => {
      return dispatch(getCompanyAccessSuccess(payload));
    })
    .catch((error) => {
      console.log(error);
      return error;
    });

  dispatch({
    type: GET_COMPANY_ACCESS.START,
    payload: request,
  });

  return request;
};

const getCompanyVenuesSuccess = (payload) => ({
  type: GET_COMPANY_VENUES.SUCCESS,
  payload,
});

export const getCompanyVenues = (id) => (dispatch) => {
  const request = getJSON(
    `${process.env.REACT_APP_API_ROOT}/iatf/admin/company/${id}/venues`,
  )
    .then((payload) => {
      return dispatch(getCompanyVenuesSuccess(payload));
    })
    .catch((error) => {
      console.log(error);
      return error;
    });

  dispatch({
    type: GET_COMPANY_VENUES.START,
    payload: request,
  });

  return request;
};

export const addAccess = (payload) => (dispatch) => {
  const request = postJSON(
    `${process.env.REACT_APP_API_ROOT}/iatf/admin/company/access`,
    payload,
  )
    .then((response) => {
      return dispatch(getCompanyAccess(payload.CompanyID));
    })
    .catch((error) => {
      console.log(error);
      return error;
    });

  dispatch({
    type: GET_COMPANY_VENUES.START,
    payload: request,
  });

  return request;
};

export const removeAccess = (
  accessCompanyId,
  companyId,
  playerId,
  accessId,
) => (dispatch) => {
  const request = deleteJSON(
    `${process.env.REACT_APP_API_ROOT}/iatf/admin/company/access/${accessCompanyId}/${playerId}/${accessId}`,
  )
    .then((response) => {
      return dispatch(getCompanyAccess(companyId));
    })
    .catch((error) => {
      console.log(error);
      return error;
    });

  dispatch({
    type: GET_COMPANY_VENUES.START,
    payload: request,
  });

  return request;
};

export const resetCompanyDetail = (payload) => ({
  type: RESET_COMPANY_DETAIL,
});
