import React, { Fragment, Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import 'normalize.css';
import { ThemeProvider } from 'styled-components';
import { theme } from './core/styles/theme';
import { GlobalStyle } from './core/styles/global';

// import { store } from '.';
// import { getSession } from './scenes/login/store/login.action';

import { AppHeader } from './components/app-header/app-header.container';
import { Main } from './components/main/main.container';
import { MuiThemeProvider } from '@material-ui/core';
import { Home } from './scenes/home/home.container';
import { Login } from './scenes/login/login.container';
import { ProtectedRoute } from './components/protected-route/protected-route.container';
import { PasswordReset } from './scenes/password-reset/password-reset.component';
import { Companies } from './scenes/companies/companies.container';
import { CompanyDetail } from './scenes/company-detail/company-detail.container';
import { EventsImport } from './scenes/events-import/events-import.container';
import { MonthlyFees } from './scenes/billing/monthly-fees/monthly-fees.component';
import { BillingCompanyDetail } from './scenes/billing/company-detail/company-detail.component';

class App extends Component {
  componentDidMount() {
    this.checkSession();
  }

  componentDidUpdate() {
    this.checkSession();
  }

  checkSession = () => {
    // if (store.getState().auth.isLoggedIn) {
    //   store.dispatch(getSession());
    // }
  };

  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <Router>
            <Fragment>
              <AppHeader />
              <Main>
                <Switch>
                  <Route exact={true} path="/login" component={Login} />
                  <Route
                    exact={true}
                    path="/password-reset"
                    component={PasswordReset}
                  />
                  <ProtectedRoute exact={true} path="/" component={Home} />
                  <ProtectedRoute
                    exact={true}
                    path="/companies"
                    component={Companies}
                  />
                  <ProtectedRoute
                    exact={true}
                    path="/company/:id"
                    component={CompanyDetail}
                  />
                  <ProtectedRoute
                    exact={true}
                    path="/events/import"
                    component={EventsImport}
                  />
                  {/* BILLING */}
                  <ProtectedRoute
                    exact={true}
                    path="/billing/companies"
                    component={MonthlyFees}
                  />
                  <ProtectedRoute
                    exact={true}
                    path="/billing/company/:id"
                    component={BillingCompanyDetail}
                  />
                  <Route component={() => <div>404 Not Found :(</div>} />
                </Switch>
              </Main>
            </Fragment>
          </Router>
        </ThemeProvider>
      </MuiThemeProvider>
    );
  }
}

export default App;
