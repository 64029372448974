import { postJSON, getJSON } from 'src/core/util/loaders';
import { GET_SCHEDULE_HOME, SET_DOCUMENT_ACKNOWLEDGE } from './home.constant';

const getScheduleSuccess = payload => ({
  type: GET_SCHEDULE_HOME.SUCCESS,
  payload,
});

const getScheduleError = error => ({
  type: GET_SCHEDULE_HOME.ERROR,
  payload: error,
});

export const getSchedule = () => dispatch => {
  const request = getJSON(`${process.env.REACT_APP_API_ROOT}/employee/schedule`)
    .then(schedule => {
      return dispatch(getScheduleSuccess(schedule));
    })
    .catch(error => {
      return dispatch(getScheduleError(error));
    });

  dispatch({
    type: GET_SCHEDULE_HOME.START,
    payload: request,
  });

  return request;
};

const setDocumentAcknowledgeSuccess = payload => ({
  type: SET_DOCUMENT_ACKNOWLEDGE.SUCCESS,
  payload,
});

const setDocumentAcknowledgeError = error => ({
  type: SET_DOCUMENT_ACKNOWLEDGE.ERROR,
  payload: error,
});

export const setDocumentAcknowledge = () => dispatch => {
  const request = postJSON(
    `${process.env.REACT_APP_API_ROOT}/settings/document-acknowledge`,
  )
    .then(acknowledge => {
      return dispatch(setDocumentAcknowledgeSuccess(acknowledge.value));
    })
    .catch(error => {
      return dispatch(setDocumentAcknowledgeError(error));
    });

  dispatch({
    type: SET_DOCUMENT_ACKNOWLEDGE.START,
    payload: request,
  });

  return request;
};
