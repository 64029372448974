import {
  GET_FEES_BY_MONTH,
  GET_FEES_BY_COMPANY,
  INSERT_FEE,
  UPDATE_FEE,
  RESET_FEES,
  FEES_SET_MONTH,
  FEES_SET_YEAR,
  GET_FEE_DETAIL,
  RESET_FEE_DETAIL,
} from './fee.constant';
import { buildUrl } from '../../../../util/url.util';
import { endpoints } from '../../../../core/constants/url.constant';
import { getJSON } from '../../../../util/loaders';
import { putJSON } from 'src/core/util/loaders';

export const setMonth = (payload) => ({
  type: FEES_SET_MONTH,
  payload,
});

export const setYear = (payload) => ({
  type: FEES_SET_YEAR,
  payload,
});

const getFeesByMonthSuccess = (payload) => ({
  type: GET_FEES_BY_MONTH.SUCCESS,
  payload,
});

const getFeesByMonthError = (error) => ({
  type: GET_FEES_BY_MONTH.ERROR,
  payload: error,
});

export const getFeesByMonth = (month) => (dispatch) => {
  const request = getJSON(
    buildUrl(endpoints.billing.fees.getFeesByMonth, month),
    {},
    {},
  )
    .then((res) => {
      return dispatch(getFeesByMonthSuccess(res));
    })
    .catch((error) => {
      return dispatch(getFeesByMonthError(error));
    });

  return dispatch({
    type: GET_FEES_BY_MONTH.START,
    payload: request,
  });
};
const getFeeDetailsSuccess = (payload) => ({
  type: GET_FEE_DETAIL.SUCCESS,
  payload,
});

const getFeeDetailsError = (error) => ({
  type: GET_FEE_DETAIL.ERROR,
  payload: error,
});

export const getFeeDetails = (id) => (dispatch) => {
  const request = getJSON(
    buildUrl(endpoints.billing.fees.getFeeDetails, id),
    {},
    {},
  )
    .then((res) => {
      return dispatch(getFeeDetailsSuccess(res));
    })
    .catch((error) => {
      return dispatch(getFeeDetailsError(error));
    });

  return dispatch({
    type: GET_FEE_DETAIL.START,
    payload: request,
  });
};

export const resetFeeDetails = () => ({
  type: RESET_FEE_DETAIL,
});

export const updateFees = (fees, month) => (dispatch) => {
  const request = putJSON(endpoints.billing.fees.update, fees, {})
    .then((res) => {
      return dispatch(getFeesByMonth(month));
    })
    .catch((error) => {
      return dispatch(getFeesByMonthError(error));
    });

  return dispatch({
    type: GET_FEES_BY_MONTH.START,
    payload: request,
  });
};

export const updateRollupFees = (fees, month) => (dispatch) => {
  const request = putJSON(endpoints.billing.fees.updateRollup, fees, {})
    .then((res) => {
      return dispatch(getFeesByMonth(month));
    })
    .catch((error) => {
      return dispatch(getFeesByMonthError(error));
    });

  return dispatch({
    type: GET_FEES_BY_MONTH.START,
    payload: request,
  });
};

export const updateFee = (fee, month) => (dispatch) => {
  console.log(fee);
  const request = putJSON(endpoints.billing.fees.update, fee, {})
    .then((res) => {
      dispatch(getFeesByMonth(month));
      return dispatch(getFeeDetails(fee[0].rollupID));
    })
    .catch((error) => {
      return dispatch(getFeeDetailsError(error));
    });

  return dispatch({
    type: GET_FEES_BY_MONTH.START,
    payload: request,
  });
};

// const getFeesByCompanySuccess = (payload) => ({
//   type: GET_FEES_BY_COMPANY.SUCCESS,
//   payload,
// });

// const getFeesByCompanyError = (error) => ({
//   type: GET_FEES_BY_COMPANY.ERROR,
//   payload: error,
// });

// export const getFeesByCompany = (id) => (dispatch) => {
//   const request = getJSON(
//     buildUrl(endpoints.billing.fees.getFeesByCompany, id),
//     {},
//     {}
//   )
//     .then((res) => {
//       return dispatch(getFeesByCompanySuccess(res));
//     })
//     .catch((error) => {
//       return dispatch(getFeesByCompanyError(error));
//     });

//   return dispatch({
//     type: GET_FEES_BY_COMPANY.START,
//     payload: request,
//   });
// };

export const resetFees = () => ({
  type: RESET_FEES,
});
