import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withTheme } from '@material-ui/core';
import { withRouter } from 'react-router-dom';

import { AppHeaderComponent } from './app-header.component';
import { logout } from 'src/scenes/login/store/login.action';
import { mapDispatchers } from 'src/core/util/map-dispatchers';
import { appHeeaderConnector } from './store/app-header.selector';

const dispatchers = mapDispatchers({
  logout,
});

export const AppHeader = compose(
  connect(appHeeaderConnector, dispatchers),
  withTheme,
  withRouter,
)(AppHeaderComponent);
