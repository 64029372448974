import React, { useState } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { Fragment } from 'react';

const APIForm = ({ selectedSource, access, accessConfig, setAccessConfig }) => {
  if (!selectedSource) {
    return null;
  }

  const config = JSON.parse(selectedSource.Config);

  if (!config.fields) {
    return (
      <Grid item xs={12}>
        This integration is not ready yet.
      </Grid>
    );
  }

  return config.fields.map((f) => (
    <Grid key={f.fieldName} item xs={12}>
      <TextField
        id={f.fieldName}
        name={f.fieldName}
        label={f.label}
        type={f.type}
        value={accessConfig[f.fieldName]}
        onChange={(e) =>
          setAccessConfig({
            ...accessConfig,
            [f.fieldName]: e.target.value,
          })
        }
        helperText={f.helperText}
      />
    </Grid>
  ));
};

export const ImportConfig = ({
  open,
  bookingSources,
  access,
  handleClose = () => null,
  handleSubmit = () => null,
}) => {
  const [source, setSource] = useState(access ? access.BookingSourceID : -1);
  const [accessConfig, setAccessConfig] = useState(
    access ? JSON.parse(access.Config) : {},
  );

  const selectedSource =
    bookingSources && bookingSources.find((s) => s.ID === source);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="Configure Booking Source"
    >
      <DialogTitle id="form-dialog-title">Configure Booking Source</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Select your booking source and enter your keys to import your bookings
          to axescores.
        </DialogContentText>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl style={{ width: '100%' }}>
              <InputLabel id="venue-select-label">
                Select Booking Source
              </InputLabel>
              <Select
                labelId="venue-select-label"
                id="venue-select"
                value={source}
                onChange={(e) => setSource(e.target.value)}
              >
                <MenuItem value={-1}>Please Select a source</MenuItem>
                {bookingSources &&
                  bookingSources.map((s) => (
                    <MenuItem key={s.ID} value={s.ID}>
                      {s.Name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <APIForm
            accessConfig={accessConfig}
            setAccessConfig={setAccessConfig}
            selectedSource={selectedSource}
            access={access}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Fragment>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            variant="contained"
            type="submit"
            color="primary"
            onClick={() =>
              handleSubmit({
                ...access,
                BookingSourceID: source,
                Config: JSON.stringify(accessConfig),
              })
            }
          >
            Save
          </Button>
        </Fragment>
      </DialogActions>
    </Dialog>
  );
};
