import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withTheme } from '@material-ui/core';
import { withRouter } from 'react-router-dom';

import { loginConnector } from 'src/scenes/login/store/login.selector';
import { MainComponent } from './main.component';

export const Main = compose(
  connect(loginConnector, null),
  withTheme,
  withRouter,
)(MainComponent);
