import { createStructuredSelector } from "reselect";
import { path } from "ramda";

export const selectLoading = path(["billing", "billingInfo", "loading"]);
export const selectBillingInfo = path(["billing", "billingInfo", "info"]);
export const selectFees = path(["billing", "billingInfo", "fees"]);

export const billingInfoConnector = createStructuredSelector({
  billingInfo: selectBillingInfo,
  fees: selectFees,
  loading: selectLoading,
});
