import {
  GET_COMPANY,
  GET_COMPANY_ACCESS,
  GET_COMPANY_VENUES,
  RESET_COMPANY_DETAIL,
} from './company-detail.constant';

const initialState = {
  company: null,
  access: [],
  venues: [],
  loading: false,
};

export const companyDetailReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case GET_COMPANY.SUCCESS:
      return {
        ...state,
        company: payload.companies[0],
        loading: false,
      };

    case GET_COMPANY.START:
      return {
        ...state,
        loading: true,
      };

    case GET_COMPANY_ACCESS.SUCCESS:
      return {
        ...state,
        access: payload.access,
        loading: false,
      };

    case GET_COMPANY_ACCESS.START:
      return {
        ...state,
        loading: true,
      };

    case GET_COMPANY_VENUES.SUCCESS:
      return {
        ...state,
        venues: payload.venues,
        loading: false,
      };

    case GET_COMPANY_VENUES.START:
      return {
        ...state,
        loading: true,
      };

    case RESET_COMPANY_DETAIL:
      return initialState;

    default:
      return state;
  }
};
