import React, { Fragment } from 'react';
import { format } from 'date-fns';
import { IconButton, Tooltip, Typography } from '@material-ui/core';
import { Delete, OpenInNew } from '@material-ui/icons';

export const bookingsColumnsBATL = [
  {
    field: 'startDatetime',
    headerName: 'Time',
    type: 'dateTime',
    width: 90,
    valueFormatter: (params) =>
      format(new Date(params.value), 'hh:mmaaaaa') + 'm',
  },
  {
    field: 'notes',
    headerName: 'Notes',
    width: 190,
    renderCell: (params) => (
      <Tooltip title={<Typography color="inherit">{params.value}</Typography>}>
        <span>{params.value}</span>
      </Tooltip>
    ),
  },
  {
    field: 'organizerName',
    headerName: 'Name',
    minWidth: 128,
    flex: 1,
  },
  {
    field: 'organizerPhoneNumber',
    headerName: 'Phone #',
    flex: 1,
    minWidth: 128,
    renderCell: (params) => <a href={`tel:${params.value}`}>{params.value}</a>,
  },
  {
    field: 'organizerEmail',
    headerName: 'Email',
    flex: 1,
    minWidth: 128,
    renderCell: (params) => (
      <a href={`mailto:${params.value}`}>{params.value}</a>
    ),
  },
  {
    field: 'orderDatetime',
    headerName: 'Order Date',
    type: 'date',
    flex: 1,
    minWidth: 128,
    valueFormatter: (params) => format(new Date(params.value), 'Pp'),
  },
];

export const eventsColumnsBATL = (deleteCallback) => [
  {
    field: 'Actions',
    width: 120,
    renderCell: (params) => (
      <Fragment>
        <IconButton
          style={{ color: '#f47174' }}
          aria-label="delete"
          onClick={() => {
            const { ID, ContestID } = params.row;

            if (
              window.confirm(
                'This booking and the contest associated will be destroyer. Are you sure?',
              )
            ) {
              deleteCallback(ID, ContestID);
            }
          }}
        >
          <Delete />
        </IconButton>
        <IconButton
          aria-label="show in axescores"
          onClick={() => {
            const { ContestID } = params.row;
            window.open(
              `${process.env.REACT_APP_AS_ADMIN_BASE}/Event/New/${ContestID}`,
              '_blank',
            );
          }}
        >
          <OpenInNew />
        </IconButton>
      </Fragment>
    ),
  },
  {
    field: 'Name',
    headerName: 'Name',
    width: 240,
    renderCell: (params) => {
      const parts = params.value.split(' | ');
      return (
        <Tooltip
          title={<Typography color="inherit">{params.value}</Typography>}
        >
          <span>
            {parts[1]} | {parts[3]} {parts[4] ? `| ${parts[4]}` : ''}
          </span>
        </Tooltip>
      );
    },
  },
  {
    field: 'Notes',
    headerName: 'Notes',
    width: 190,
    renderCell: (params) => (
      <Tooltip title={<Typography color="inherit">{params.value}</Typography>}>
        <span>{params.value}</span>
      </Tooltip>
    ),
  },
  {
    field: 'OrganizerName',
    headerName: 'Organizer',
    minWidth: 128,
    flex: 1,
  },
  {
    field: 'OrganizerPhoneNumber',
    headerName: 'Phone #',
    flex: 1,
    minWidth: 128,
    renderCell: (params) => <a href={`tel:${params.value}`}>{params.value}</a>,
  },
  {
    field: 'OrganizerEmail',
    headerName: 'Email',
    flex: 1,
    minWidth: 128,
    renderCell: (params) => (
      <a href={`mailto:${params.value}`}>{params.value}</a>
    ),
  },
  {
    field: 'OrderDatetime',
    headerName: 'Order Date',
    type: 'date',
    flex: 1,
    minWidth: 128,
    valueFormatter: (params) => format(new Date(params.value), 'Pp'),
  },
];
