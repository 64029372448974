import { Formik } from 'formik';
import React from 'react';
// import * as Yup from 'yup';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import { Fragment } from 'react';

const INITIAL = {
  contactName: '',
  email: '',
  phone: '',
};

export const BillingContactModal = ({
  open,
  handleClose,
  contact,
  onDelete,
  submit = () => new Promise((r) => r(null)),
}) => {
  var isEdit = !!contact;
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <Formik
        initialValues={contact ? { ...INITIAL, ...contact } : INITIAL}
        onSubmit={(values, { setSubmitting }) => {
          const payload = {
            Name: values.contactName,
            Email: values.email,
            Phone: values.phone,
          };
          submit(
            isEdit
              ? {
                  ...payload,
                  ID: contact.billingContactID,
                }
              : payload,
            isEdit,
          ).then(() => setSubmitting(false));
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setValues,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogTitle id="form-dialog-title">
                Create A New Billing Contact
              </DialogTitle>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      autoFocus
                      error={!!(errors.contactName && touched.contactName)}
                      helperText={touched.contactName && errors.contactName}
                      label="Name"
                      type="text"
                      fullWidth
                      name="contactName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.contactName}
                    />
                  </Grid>
                  <Grid item xs={6} />
                  <Grid item xs={6}>
                    <TextField
                      error={!!(errors.email && touched.email)}
                      helperText={touched.email && errors.email}
                      label="Email"
                      type="text"
                      fullWidth
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      error={!!(errors.phone && touched.phone)}
                      helperText={touched.phone && errors.phone}
                      label="Phone"
                      type="text"
                      fullWidth
                      name="phone"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.phone}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                {!!contact === true && (
                  <Button
                    onClick={() => {
                      if (
                        window.confirm(
                          'Are you sure you want to delete the contact "' +
                            values.contactName +
                            '" from the system?',
                        )
                      ) {
                        onDelete(contact.billingContactID);
                      }
                    }}
                    color="secondary"
                    variant="contained"
                    style={{ marginRight: 'auto' }}
                  >
                    Delete
                  </Button>
                )}
                {!isSubmitting ? (
                  <Fragment>
                    <Button onClick={handleClose} color="primary">
                      Cancel
                    </Button>
                    <Button
                      disabled={isSubmitting}
                      type="submit"
                      color="primary"
                    >
                      {!!contact === true ? 'Update' : 'Submit'}
                    </Button>
                  </Fragment>
                ) : (
                  <Fragment>
                    <Typography variant="caption">
                      This might take a few seconds, please wait...
                    </Typography>
                    <CircularProgress size={24} />
                  </Fragment>
                )}
              </DialogActions>
            </form>
          );
        }}
      </Formik>
    </Dialog>
  );
};
