import { compose, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import { withTheme } from '@material-ui/core';

import { HomeComponent } from './home.component';
import { homeConnector } from './store/home.selector';
import { setDocumentAcknowledge, getSchedule } from './store/home.action';
import { mapDispatchers } from 'src/core/util/map-dispatchers';
import { requestPasswordReset } from '../login/store/login.action';

const dispatchers = mapDispatchers({
  setDocumentAcknowledge,
  getSchedule,
  requestPasswordReset,
});

const lifecycles = lifecycle({
  componentDidMount() {},
});

export const Home = compose(
  connect(homeConnector, dispatchers),
  withTheme,
  lifecycles,
)(HomeComponent);
