import { path } from 'ramda';
import { createStructuredSelector, createSelector } from 'reselect';
import { selectConfig } from 'src/scenes/config/config.selector';

export const selectCompanies = path(['companies', 'companies']);
export const selectLoading = path(['companies', 'loading']);

export const selectCountries = createSelector(
  selectConfig,
  (config) =>
    config.countries &&
    config.countries.map((c) => ({ ID: c.ID, Name: c.Name })),
);

export const selectRegions = createSelector(
  selectConfig,
  (config) =>
    config.countries &&
    config.countries.reduce((acc, c) => [...acc, ...c.regions], []),
);

export const selectFeatures = createSelector(
  selectConfig,
  (config) => config.features,
);

export const companiesConnector = createStructuredSelector({
  companies: selectCompanies,
  loading: selectLoading,
  countries: selectCountries,
  regions: selectRegions,
  features: selectFeatures,
});
