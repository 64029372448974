import { combineReducers } from "redux";
import { billingInfoReducer } from "./billing-info/store/billing-info.reducer";
import { companyReducer } from "./company/store/company.reducer";
import { feeReducer } from "./fee/store/fee.reducer";
import { homeReducer } from "./home/store/home.reducer";

export const billingReducer = combineReducers({
  home: homeReducer,
  fees: feeReducer,
  company: companyReducer,
  billingInfo: billingInfoReducer,
});
