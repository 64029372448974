import { IconButton } from '@material-ui/core';
import { ZoomIn } from '@material-ui/icons';
import React from 'react';
import { Link } from 'react-router-dom';

export const cols = (getFeeDetails, d, setRollupID) => [
  {
    field: 'name',
    headerName: 'Company',
    minWidth: 128,
    flex: 1,
    renderCell: (params) => {
      const id = params.row.companyID;
      return <Link to={`/billing/company/${id}`}>{params.row.name}</Link>;
    },
  },
  {
    field: 'totalAmount',
    headerName: 'Total Amount',
    minWidth: 128,
    flex: 1,
    type: 'number',
    valueFormatter: (params) => `$${params.value}`,
  },
  {
    field: 'approvedTotalAmount',
    headerName: 'Approved Total Amount',
    minWidth: 128,
    flex: 1,
    type: 'number',
    valueFormatter: (params) => `$${params.value}`,
  },
  {
    field: 'approved',
    headerName: 'Approved',
    flex: 0.5,
    valueFormatter: (params) => (params.value ? '✅' : '❌'),
  },
  {
    field: 'paid',
    headerName: 'Paid',
    flex: 0.5,
    valueFormatter: (params) => (params.value ? '✅' : '❌'),
  },
  {
    field: 'id',
    headerName: 'Actions',
    flex: 0.5,
    renderCell: (params) => (
      <IconButton
        color="primary"
        onClick={() => {
          setRollupID(params.value);
          d(getFeeDetails(params.value));
        }}
      >
        <ZoomIn />
      </IconButton>
    ),
  },
];
