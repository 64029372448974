const { REACT_APP_API_ROOT, REACT_APP_ASSET_ROOT, REACT_APP_BILLING_API_ROOT } =
  process.env;

export const endpoints = {
  lobby: {
    recent: `${REACT_APP_API_ROOT}/app-lobby-recent`,
    requests: `${REACT_APP_API_ROOT}/app-lobby-requests`,
    online: `${REACT_APP_API_ROOT}/app-lobby-online`,
    search: `${REACT_APP_API_ROOT}/app-lobby-search`,
    roulette: `${REACT_APP_API_ROOT}/app-lobby-roulette`,
    chat: `${REACT_APP_API_ROOT}/app-group-messages`,
    newChat: `${REACT_APP_API_ROOT}/app-any-new-chat`,
  },
  team: {
    teams: `${REACT_APP_API_ROOT}/player/all-teams`,
    profile: `${REACT_APP_API_ROOT}/team/:id`,
    roster: `${REACT_APP_API_ROOT}/team/roster/:teamId`,
    matchRoster: `${REACT_APP_API_ROOT}/team/roster/:teamId/:teamMatchId`,
    stats: `${REACT_APP_API_ROOT}/team/stats/:teamId`,
    schedule: `${REACT_APP_API_ROOT}/team/schedule/:teamId`,
    playerInvitations: `${REACT_APP_API_ROOT}/team-league/player-invite`,
    teamInvitations: `${REACT_APP_API_ROOT}/team-league/team-invite/:teamId`,
    sendInvite: `${REACT_APP_API_ROOT}/team-league/new-invite/:teamId/:playerId`,
    teamInfo: `${REACT_APP_API_ROOT}/team/info/:teamId`,
    retire: `${REACT_APP_API_ROOT}/team/retire/:teamId`,
    create: `${REACT_APP_API_ROOT}/team/new`,
    updateProfile: `${REACT_APP_API_ROOT}/team/profile/:teamId`,
    invitationRespond: `${REACT_APP_API_ROOT}/team-league/invitaion/respond/:invitationId/:statusId`,
    removePlayer: `${REACT_APP_API_ROOT}/team/remove/:teamId/:playerId/:rosterId`,
    lineups: `${REACT_APP_API_ROOT}/team/lineups/:teamId`,
    updateLineups: `${REACT_APP_API_ROOT}/team/lineups`,
  },
  throwCount: `${REACT_APP_API_ROOT}/throws`,
  leagueAverageHome: `${REACT_APP_API_ROOT}/average/league?n=5&p=0`,
  bullseyeAccuracyHome: `${REACT_APP_API_ROOT}/accuracy/bullseye?n=5&p=0`,
  clutchAccuracyHome: `${REACT_APP_API_ROOT}/accuracy/clutch?n=5&p=0`,
  playerAverageHome: `${REACT_APP_API_ROOT}/average/player?n=5&p=0`,
  attendanceHome: `${REACT_APP_API_ROOT}/attendance?n=5&p=0`,
  improvedHome: `${REACT_APP_API_ROOT}/improved?n=5&p=0`,
  login: `${REACT_APP_API_ROOT}/login`,
  socialLogin: `${REACT_APP_API_ROOT}/social-login`,
  signup: `${REACT_APP_API_ROOT}/signup`,
  passwordRecovery: `${REACT_APP_API_ROOT}/password-recovery`,
  passwordReset: `${REACT_APP_API_ROOT}/password-reset`,
  league: `${REACT_APP_API_ROOT}/league/:leagueId`,
  player: `${REACT_APP_API_ROOT}/player/:playerId`,
  playerHome: `${REACT_APP_API_ROOT}/app/home`,
  playerSeason: `${REACT_APP_API_ROOT}/player/:playerId/season/:seasonId`,
  compare: `${REACT_APP_API_ROOT}/compare/:leftId/:rightId`,
  search: `${REACT_APP_API_ROOT}/find`,
  playerSearch: `${REACT_APP_API_ROOT}/player-search`,
  emailConfirmation: `${REACT_APP_API_ROOT}/email-confirmation`,
  matchDetail: `${REACT_APP_API_ROOT}/match/:matchId`,
  teamMatchDetail: `${REACT_APP_API_ROOT}/team/match/:matchId`,
  allLeagues: `${REACT_APP_API_ROOT}/leagues`,
  allPlayers: `${REACT_APP_API_ROOT}/players`,
  clubs: `${REACT_APP_API_ROOT}/org/active`,
  records: `${REACT_APP_API_ROOT}/records`,
  club75: `${REACT_APP_API_ROOT}/club/75`,
  club81: `${REACT_APP_API_ROOT}/club/81`,
  globalStandings: `${REACT_APP_API_ROOT}/ratings`,
  iatfRegions: `${REACT_APP_API_ROOT}/tournament/regions`,
  tournamentStandings: `${REACT_APP_API_ROOT}/tournament-ratings`,
  liveSearch: `${REACT_APP_API_ROOT}/live/matches`,
  liveLeague: `${REACT_APP_API_ROOT}/live/matches/:leagueid`,
  champPoints: `${REACT_APP_API_ROOT}/champ-points/:leagueid`,
  tether: `${REACT_APP_API_ROOT}/tether`,
  profile: `${REACT_APP_API_ROOT}/me/profile`,
  passwordChange: `${REACT_APP_API_ROOT}/me/password`,
  deleteAccount: `${REACT_APP_API_ROOT}/me/delete-account`,
  session: `${REACT_APP_API_ROOT}/me`,
  leagueChampPoints: `${REACT_APP_API_ROOT}/league/:leagueId/points`,
  authCheck: `${REACT_APP_API_ROOT}/me`,
  tournaments: `${REACT_APP_API_ROOT}/tournaments`,
  tournamentSearch: `${REACT_APP_API_ROOT}/tournament/search?q=`,
  teamSearch: `${REACT_APP_API_ROOT}/team/search?q=`,
  waiver: {
    venue: `${REACT_APP_API_ROOT}/waiver/venue`,
    email: `${REACT_APP_API_ROOT}/waiver/email`,
    submit: `${REACT_APP_API_ROOT}/waiver`,
  },
  tournament: {
    brackets: `${REACT_APP_API_ROOT}/contest/:id/bracket`,
    onDeck: `${REACT_APP_API_ROOT}/contest/:id/ondeck/:venueId`,
    player: `${REACT_APP_API_ROOT}/contest/:id/player/:playerId`,
    stats: `${REACT_APP_API_ROOT}/contest/:id/stats`,
    playerTournaments: `${REACT_APP_API_ROOT}/tournament/player/:playerId`,
  },
  iatc: {
    brackets: `${REACT_APP_API_ROOT}/contest/iatc/bracket`,
    onDeck: `${REACT_APP_API_ROOT}/contest/iatc/ondeck/:venueId`,
    player: `${REACT_APP_API_ROOT}/contest/iatc/player/:playerId`,
    stats: `${REACT_APP_API_ROOT}/contest/iatc/stats`,
    ambidextrous: {
      onDeck: `${REACT_APP_API_ROOT}/contest/ambidextrous/ondeck`,
      results: `${REACT_APP_API_ROOT}/contest/skillz/ambi/results`,
    },
    aroundTheWorld: {
      onDeck: `${REACT_APP_API_ROOT}/contest/aroundTheWorld/ondeck`,
      results: `${REACT_APP_API_ROOT}/contest/skillz/aroundTheWorld/results`,
    },
    doubles: {
      onDeck: `${REACT_APP_API_ROOT}/contest/doubles/ondeck`,
      results: `${REACT_APP_API_ROOT}/contest/skillz/doubles/results`,
    },
    timeTrial: {
      onDeck: `${REACT_APP_API_ROOT}/contest/timeTrial/ondeck`,
      results: `${REACT_APP_API_ROOT}/contest/skillz/timeTrial/results`,
    },
  },
  billing: {
    fees: {
      getFeesByMonth: `${REACT_APP_BILLING_API_ROOT}/month-rollup-fees/:date`,
      getFeesByCompany: `${REACT_APP_BILLING_API_ROOT}/companyfees/:id`,
      update: `${REACT_APP_BILLING_API_ROOT}/update/fee`,
      updateRollup: `${REACT_APP_BILLING_API_ROOT}/update/rollup-fee`,
      getFeeDetails: `${REACT_APP_BILLING_API_ROOT}/feedetails/:id`,
    },
    companies: {
      getCompanies: `${REACT_APP_BILLING_API_ROOT}/billingmachine/all-companies`,
    },
    billingInfo: {
      getInfo: `${REACT_APP_BILLING_API_ROOT}/detailedbillinginfo/:id`,
      getFees: `${REACT_APP_BILLING_API_ROOT}/companyfees/:id`,
      updateCC: `${REACT_APP_BILLING_API_ROOT}/update/payment`,
      updateAddress: `${REACT_APP_BILLING_API_ROOT}/update/address`,
      updateContact: `${REACT_APP_BILLING_API_ROOT}/update/billingcontact`,
      createCC: `${REACT_APP_BILLING_API_ROOT}/create/payment/:id`,
      createContact: `${REACT_APP_BILLING_API_ROOT}/create/billingcontact/:id`,
      deleteCC: `${REACT_APP_BILLING_API_ROOT}/delete/paymentinfo/:id`,
      deleteContact: `${REACT_APP_BILLING_API_ROOT}/delete/billingcontact/:id`,
    },
  },
};

export const sortRoutes = {
  leagueAverage: "league-average",
  collinsRating: "collins-rating",
  tournamentRating: "tournament-rating",
  bullseyeAccuracy: "bullseye-accuracy",
  clutchAccuracy: "clutch-accuracy",
  highestPlayerAverage: "highest-player-average",
  longestAttendanceStreak: "longest-attendance-streak",
  mostImprovedPlayer: "most-improved-player",
};

export const externalUrls = {
  iatf: "https://iatf.com",
  rules: "https://iatf.com/rules-guidelines",
  trainingVideos: "https://axescores.com/Support#1",
  features: "http://www.nationalaxe.com/features.html",
  store: "https://iatf.myshopify.com/",
  news: "https://iatf.com/about/news-updates",
  tournaments: "https://iatf.com/tournaments",
  terminology: "https://iatf.com/rules-guidelines/672",
  social: {
    twitter: "https://twitter.com/inter_axe",
    facebook: "https://www.facebook.com/interaxe",
    instagram: "https://www.instagram.com/interaxe/",
  },
};

export const baseAssetUrl = REACT_APP_ASSET_ROOT;
export const vintageBaseUrl = "https://vintage.axescores.com";
