import { GET_COMPANIES } from './companies.constant';

const initialState = {
  companies: [],
  loading: false,
};

export const companiesReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case GET_COMPANIES.SUCCESS:
      return {
        companies: payload,
        loading: false,
      };

    case GET_COMPANIES.START:
      return {
        ...state,
        loading: true,
      };

    default:
      return state;
  }
};
