import { Box, Paper, Typography } from '@material-ui/core';
import React from 'react';
import { TableBase } from 'src/components/table/table-base.component';

export const TablePastCharges = ({
  setSelectedHistory,
  setHistoryModal,
  config,
  charges,
}) => (
  <Paper
    variant="outlined"
    style={{
      padding: '16px 32px 32px 32px',
      marginBottom: 32,
    }}
    id="past-charges"
  >
    <Box padding="16px 0 32px 0">
      <Box display="flex">
        <Typography variant="h5">Past Charges</Typography>
      </Box>
      <TableBase
        rows={charges}
        columns={config}
        scroll
        onRowClick={(row) => {
          setSelectedHistory(row);
          setHistoryModal(true);
        }}
      />
    </Box>
  </Paper>
);
