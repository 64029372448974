import { postJSON, getJSON, putJSON, deleteJSON } from 'src/core/util/loaders';
import {
  EXPORT_BOOKINGS_FROM_SOURCE,
  IMPORT_EVENTS_TO_AXESCORES,
  GET_BOOKING_ACCESS,
  SET_BOOKING_ACCESS,
  GET_AUTHORIZED_VENUES,
  GET_ARENAS,
} from './events-import.constant';

const exportBookingsSuccess = (payload) => ({
  type: EXPORT_BOOKINGS_FROM_SOURCE.SUCCESS,
  payload,
});

const exportBookingsError = (payload) => ({
  type: EXPORT_BOOKINGS_FROM_SOURCE.ERROR,
  payload,
});

export const exportBookingsFromBATL =
  (importAPIKey, date, venueId) => (dispatch) => {
    const request = getJSON(
      `${process.env.REACT_APP_API_ROOT}/bookings/export/batl/${venueId}/${date}/${importAPIKey}`,
    )
      .then((bookings) => {
        return dispatch(exportBookingsSuccess(bookings));
      })
      .catch((error) => {
        return dispatch(exportBookingsError(error));
      });

    dispatch({
      type: EXPORT_BOOKINGS_FROM_SOURCE.START,
      payload: request,
    });

    return request;
  };

const importEventsSuccess = (payload) => ({
  type: IMPORT_EVENTS_TO_AXESCORES.SUCCESS,
  payload,
});

const importEventsError = (error) => ({
  type: IMPORT_EVENTS_TO_AXESCORES.ERROR,
  payload: error,
});

export const importEventsToAxescores = (bookings, venueId) => (dispatch) => {
  const request = postJSON(
    `${process.env.REACT_APP_API_ROOT}/bookings/bookings-import`,
    bookings,
  )
    .then((events) => {
      return dispatch(importEventsSuccess(events));
    })
    .catch((error) => {
      return dispatch(importEventsError(error));
    });

  dispatch({
    type: IMPORT_EVENTS_TO_AXESCORES.START,
    payload: request,
  });

  return request;
};

const getBookingAccessSuccess = (payload) => ({
  type: GET_BOOKING_ACCESS.SUCCESS,
  payload,
});

const getBookingAccessError = (error) => ({
  type: GET_BOOKING_ACCESS.ERROR,
  payload: error,
});

export const getBookingAccess = (venueId) => (dispatch) => {
  const request = getJSON(
    `${process.env.REACT_APP_API_ROOT}/bookings/booking-access/${venueId}`,
  )
    .then((access) => {
      return dispatch(getBookingAccessSuccess(access));
    })
    .catch((error) => {
      return dispatch(getBookingAccessError(error));
    });

  dispatch({
    type: GET_BOOKING_ACCESS.START,
    payload: request,
  });

  return request;
};

const setBookingAccessSuccess = (payload) => ({
  type: GET_BOOKING_ACCESS.SUCCESS,
  payload,
});

const setBookingAccessError = (error) => ({
  type: GET_BOOKING_ACCESS.ERROR,
  payload: error,
});

export const setBookingAccess = (access) => (dispatch) => {
  const request = putJSON(
    `${process.env.REACT_APP_API_ROOT}/bookings/booking-access/${access.VenueID}`,
    access,
  )
    .then((success) => {
      if (success === true) {
        return dispatch(setBookingAccessSuccess());
      }
      throw new Error(
        'Could not save your configuration. Please reach out to S&T.',
      );
    })
    .catch((error) => {
      return dispatch(setBookingAccessError(error));
    });

  dispatch({
    type: SET_BOOKING_ACCESS.START,
    payload: request,
  });

  return request;
};

const getAuthorizedVenuesSuccess = (payload) => ({
  type: GET_AUTHORIZED_VENUES.SUCCESS,
  payload,
});

const getAuthorizedVenuesError = (error) => ({
  type: GET_AUTHORIZED_VENUES.ERROR,
  payload: error,
});

export const getAuthorizedVenues = () => (dispatch) => {
  const request = getJSON(
    `${process.env.REACT_APP_API_ROOT}/bookings/booking-access/venue-access`,
  )
    .then((venues) => {
      return dispatch(getAuthorizedVenuesSuccess(venues));
    })
    .catch((error) => {
      return dispatch(getAuthorizedVenuesError(error));
    });

  dispatch({
    type: GET_AUTHORIZED_VENUES.START,
    payload: request,
  });

  return request;
};

const getArenasSuccess = (payload) => ({
  type: GET_ARENAS.SUCCESS,
  payload,
});

export const getArenas = () => (dispatch) => {
  const request = getJSON(
    `${process.env.REACT_APP_API_ROOT}/bookings/arenas`,
  ).then((arenas) => {
    return dispatch(getArenasSuccess(arenas));
  });

  dispatch({
    type: GET_ARENAS.START,
    payload: request,
  });

  return request;
};

export const deleteImportedBookingWithContest =
  (bookingId, contestId) => (dispatch) =>
    deleteJSON(
      `${process.env.REACT_APP_API_ROOT}/bookings/booking/${bookingId}/contest/${contestId}`,
    );
