import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import React, { Fragment, useState } from 'react';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Settings } from '@material-ui/icons';
import { format } from 'date-fns';
import { ImportConfig } from './import-config.component';
import {
  bookingsColumnsBATL,
  eventsColumnsBATL,
} from './store/event-import.table';
import { ImportModal } from './import-modal.component';

export const EventsImportComponent = ({
  venues,
  arenas,
  bookings,
  bookingsLoading,
  bookingsError,
  bookingsImportLoading,
  bookingsImportError,
  bookingSources,
  events,
  access,
  accessError,
  importEventsToAxescores,
  exportBookingsFromBATL,
  deleteImportedBookingWithContest,
  getBookingAccess,
  setBookingAccess,
}) => {
  const [showConfig, setShowConfig] = useState(false);
  const [showImport, setShowImport] = useState(false);
  const [selectedVenue, setSelectedVenue] = useState(-1);
  const [importDate, setImportDate] = useState(new Date());
  const [configLoading, setConfigLoading] = useState(false);
  const [selectedBookings, setSelectedBookings] = useState([]);

  return (
    <Fragment>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Box padding={2}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h4" gutterBottom>
                Import Events
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" alignItems="end">
                <Button
                  disabled={selectedVenue === -1 || configLoading}
                  color="primary"
                  size="small"
                  style={{
                    minWidth: 'auto',
                    marginRight: 8,
                    padding: '4px 6px',
                  }}
                  variant="contained"
                  onClick={() => {
                    setShowConfig(true);
                  }}
                >
                  <Settings />
                </Button>
                <FormControl style={{ marginRight: 8, minWidth: 128 }}>
                  <InputLabel id="venue-select-label">Select Venue</InputLabel>
                  <Select
                    labelId="venue-select-label"
                    id="venue-select"
                    value={selectedVenue}
                    disabled={configLoading}
                    onChange={(e) => {
                      const vid = e.target.value;
                      setConfigLoading(true);
                      getBookingAccess(vid).then(() => {
                        setSelectedVenue(e.target.value);
                        setConfigLoading(false);
                      });
                    }}
                  >
                    {!venues ? (
                      <MenuItem value={-1}>Loading...</MenuItem>
                    ) : (
                      [
                        <MenuItem key="-1" value={-1}>
                          Select...
                        </MenuItem>,
                        ...venues.map((v) => (
                          <MenuItem key={v.ID} value={v.ID}>
                            {v.Name}
                          </MenuItem>
                        )),
                      ]
                    )}
                  </Select>
                </FormControl>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="yyyy-MM-dd"
                  label="Select Date To Import"
                  style={{ maxWidth: 160, marginRight: 8 }}
                  value={importDate}
                  onChange={(e) => {
                    setImportDate(e);
                  }}
                />
                <Button
                  color="primary"
                  disabled={
                    selectedVenue === -1 || configLoading || bookingsLoading
                  }
                  variant="contained"
                  size="small"
                  onClick={() =>
                    !access
                      ? alert(
                          'Please click on the gear icon to configure your import source first.',
                        )
                      : exportBookingsFromBATL(
                          JSON.parse(access.Config).APIKey,
                          format(importDate, 'yyyy-MM-dd'),
                          selectedVenue,
                        )
                  }
                >
                  GO
                </Button>
              </Box>
            </Grid>
            {/*  */}
            <Grid item xs={6}>
              <Typography variant="h5">Bookings</Typography>
              <Typography variant="caption" display="block" gutterBottom>
                Bookings that are available to import from the source.
              </Typography>
              <Box height={'calc(100vh - 290px)'}>
                <DataGrid
                  rows={bookings || []}
                  columns={bookingsColumnsBATL}
                  checkboxSelection
                  disableSelectionOnClick
                  disableColumnMenu
                  selectionModel={selectedBookings}
                  onSelectionModelChange={(m) => setSelectedBookings(m)}
                  size="small"
                />

                {selectedBookings.length > 0 && (
                  <Button
                    color="primary"
                    variant="contained"
                    style={{ marginTop: -72, marginLeft: 8 }}
                    onClick={() => setShowImport(true)}
                  >
                    Import {selectedBookings.length} items
                  </Button>
                )}
              </Box>
            </Grid>
            {/*  */}
            <Grid item xs={6}>
              <Typography variant="h5">Events</Typography>
              <Typography variant="caption" display="block" gutterBottom>
                Bookings that are imported to axescores.com.
              </Typography>
              <Box height={'calc(100vh - 290px)'}>
                <DataGrid
                  disableColumnMenu
                  rows={events || []}
                  columns={eventsColumnsBATL((bookingId, contestId) => {
                    deleteImportedBookingWithContest(bookingId, contestId).then(
                      ({ success }) =>
                        success
                          ? exportBookingsFromBATL(
                              JSON.parse(access.Config).APIKey,
                              format(importDate, 'yyyy-MM-dd'),
                              selectedVenue,
                            )
                          : alert('An unexpected error occured.'),
                    );
                  })}
                />
                {selectedVenue !== -1 && (
                  <Button
                    color="primary"
                    variant="contained"
                    href={`${process.env.REACT_APP_AS_ADMIN_BASE}/Event/WaiverVenue/${selectedVenue}`}
                    target="_blank"
                    style={{ marginTop: -72, marginLeft: 8 }}
                  >
                    Assign Waivers
                  </Button>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </MuiPickersUtilsProvider>
      {showConfig && (
        <ImportConfig
          bookingSources={bookingSources}
          access={access}
          handleClose={() => {
            setShowConfig(false);
          }}
          handleSubmit={(access) => {
            setBookingAccess({ ...access, VenueID: selectedVenue })
              .then(() => {
                return getBookingAccess(selectedVenue);
              })
              .then(() => {
                setShowConfig(false);
              })
              .catch((e) => alert(e));
          }}
          open={showConfig}
        />
      )}
      {showImport && (
        <ImportModal
          selectedBookings={
            bookings
              ? bookings.filter((b) => selectedBookings.includes(b.bookingID))
              : []
          }
          venues={venues}
          arenas={arenas}
          venueID={selectedVenue}
          open={showImport}
          importEvents={importEventsToAxescores}
          callback={() =>
            exportBookingsFromBATL(
              JSON.parse(access.Config).APIKey,
              format(importDate, 'yyyy-MM-dd'),
              selectedVenue,
            )
          }
          handleClose={() => {
            setShowImport(false);
          }}
        />
      )}
    </Fragment>
  );
};
