import React, { Fragment, useState } from 'react';
// import * as Yup from 'yup';

import {
  Avatar,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { Face, Storefront, Work } from '@material-ui/icons';
import { Formik } from 'formik';
import {
  peopleConfig,
  venuesConfig,
} from '../companies/store/companies.constant';
import { TableBase } from 'src/components/table/table-base.component';
import { CreateCompany } from '../companies/create-company.component';
import { getCompanyLogo } from 'src/core/util/string';
import { getFeatures } from 'src/core/util/bit';

// const Schema = Yup.object().shape({
//   Name: Yup.string()
//     .min(2, 'Too Short! Min 2, Max 128.')
//     .max(128, 'Too Long! Min 2, Max 128.')
//     .required('Required.'),
//   Features: Yup.array().min(1, 'Select at least one.').required('Required.'),
//   Street: Yup.string()
//     .min(2, 'Too Short! Min 2, Max 128.')
//     .max(128, 'Too Long! Min 2, Max 128.')
//     .required('Required.'),
//   Premise: Yup.string().max(16, 'Too Long! Max 16.').required('Required.'),
//   SubPremise: Yup.string(),
//   PostalCode: Yup.string().max(16, 'Too Long! Max 16.').required('Required.'),
//   Locality: Yup.string().max(128, 'Too Long! Max 128.').required('Required.'),
//   SubRegion: Yup.string(),
//   Region: Yup.string(),
//   Country: Yup.string().required('Required.'),
// });

export const CompanyDetailComponent = ({
  updateCompany,
  countries,
  regions,
  features,
  company,
  access,
  venues,
  match,
  updateCompanyVenue,
  createCompanyVenue,
  addAccess,
  removeAccess,
}) => {
  const [image, setImage] = useState(getCompanyLogo(match.params.id));
  const [venueForm, setVenueForm] = useState(false);
  const [personForm, setPersonForm] = useState(false);

  const INITIAL =
    !company || !features || !company.Address
      ? { Address: {}, Features: [] }
      : {
          Name: company.Name,
          Features: getFeatures(
            company.Features,
            features.map((f) => f.ID),
          ),
          Street: company.Address.Street,
          Premise: company.Address.Premise,
          SubPremise: company.Address.SubPremise,
          PostalCode: company.Address.PostalCode,
          Locality: company.Address.Locality,
          SubRegion: company.Address.SubRegion,
          Region: company.Address.Region,
          Country: company.Address.Country,
        };

  const setSelectedImage = (e) => {
    const { target } = e;
    const files = target.files;
    if (!files[0]) {
      return;
    }
    const filesize = (files[0].size / 1024 / 1024).toFixed(4);

    if (filesize > 5) {
      return alert('Please select a file that is under 5MB.');
    }
    if (FileReader && files && files.length) {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        console.log(fileReader.result);
        setImage(fileReader.result);
      };
      fileReader.readAsDataURL(files[0]);
    }
  };

  return !features || !company || !venues || !access ? null : (
    <Fragment>
      <Grid
        style={{ padding: 16, margin: 0, width: '100%' }}
        container
        spacing={2}
      >
        <Grid item xs={3}>
          <List
            component="nav"
            aria-label="main mailbox folders"
            style={{
              backgroundColor: '#fff',
              position: 'sticky',
              zIndex: 999,
              top: 90,
            }}
          >
            {company && (
              <ListItem alignItems="center">
                <ListItemAvatar>
                  <Avatar alt={company.Name} src={image} />
                </ListItemAvatar>
                <ListItemText primary={company.Name} />
              </ListItem>
            )}
            <Divider style={{ margin: '12px 0' }} />
            <ListItem
              button
              onClick={(e) => {
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
              }}
            >
              <ListItemIcon>
                <Work />
              </ListItemIcon>
              <ListItemText primary="Company Info" />
            </ListItem>
            <ListItem
              button
              onClick={(e) => {
                const el = document.getElementById('venues');
                window.scrollTo({
                  top: el.offsetTop - 90,
                  left: 0,
                  behavior: 'smooth',
                });
              }}
            >
              <ListItemIcon>
                <Storefront />
              </ListItemIcon>
              <ListItemText primary="Venues" />
            </ListItem>
            <ListItem
              button
              onClick={(e) => {
                const el = document.getElementById('people');
                window.scrollTo({
                  top: el.offsetTop - 90,
                  left: 0,
                  behavior: 'smooth',
                });
              }}
            >
              <ListItemIcon>
                <Face />
              </ListItemIcon>
              <ListItemText primary="People" />
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={9}>
          <Paper
            variant="outlined"
            style={{
              padding: '16px 32px 32px 32px',
              marginBottom: 32,
            }}
          >
            {company && (
              <Box>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box padding="16px 0 32px 0">
                      <Typography variant="h5">Company Info</Typography>
                    </Box>
                    <Box
                      display="flex"
                      alignItems="center"
                      position="relative"
                      marginBottom="32px"
                    >
                      <Avatar
                        alt="BATL"
                        src={image}
                        style={{ width: 128, height: 'auto' }}
                        variant="square"
                      />
                      <Box marginLeft="32px">
                        <Typography variant="h6">
                          Change Company Logo
                        </Typography>
                        <Typography variant="subtitle2">
                          Prefered Format: PNG or JPG 512 x 512
                        </Typography>
                      </Box>
                      <input
                        type="file"
                        accept="image/jpeg, image/png"
                        onChange={setSelectedImage}
                        style={{
                          opacity: 0,
                          width: '100%',
                          height: '100%',
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          zIndex: 999,
                          cursor: 'pointer',
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Formik
                      initialValues={INITIAL}
                      // validationSchema={Schema}
                      onSubmit={(values, { setSubmitting }) => {
                        const payload = {
                          ...values,
                          ID: parseInt(match.params.id, 10),
                          AddressID: parseInt(company.AddressID, 10),
                          SubDomain: company.SubDomain,
                          Descrip: company.Descrip,
                          Venues: company.Venues,
                          Pic: image.startsWith('data:image')
                            ? image.split(';base64,')[1]
                            : null,
                          Features: values.Features.reduce(
                            (acc, item) => acc + item,
                            0,
                          ),
                          Address: {
                            ID: parseInt(company.AddressID, 10),
                            Premise: values.Premise,
                            Street: values.Street,
                            SubPremise: values.SubPremise,
                            Locality: values.Locality,
                            PostalCode: values.PostalCode,
                            Country: values.Country,
                            Region: values.Region,
                            SubRegion: values.SubRegion,
                          },
                        };
                        updateCompany(match.params.id, payload).then((r) => {
                          setSubmitting(false);
                          if (r instanceof Error) {
                            return alert(r);
                          }
                          alert('Company Info is updated!');
                        });
                      }}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setValues,
                      }) => {
                        console.log(values);
                        const _regions = !regions
                          ? []
                          : regions.filter((r) =>
                              values.Country
                                ? r.CountryID === values.Country
                                : false,
                            );

                        if (!_regions.length && values.Region !== '') {
                          setValues({ ...values, Region: '' });
                        }

                        return (
                          <form onSubmit={handleSubmit}>
                            <Grid container spacing={2}>
                              <Grid item xs={6}>
                                <TextField
                                  error={!!(errors.Name && touched.Name)}
                                  helperText={touched.Name && errors.Name}
                                  label="Company Name*"
                                  type="text"
                                  fullWidth
                                  name="Name"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.Name}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <FormControl
                                  style={{ width: '100%' }}
                                  error={
                                    !!(errors.Features && touched.Features)
                                  }
                                >
                                  <InputLabel>Select Features*</InputLabel>
                                  <Select
                                    multiple
                                    fullWidth
                                    name="Features"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.Features}
                                    renderValue={(selected) => (
                                      <div
                                        style={{
                                          display: 'flex',
                                          flexWrap: 'wrap',
                                        }}
                                      >
                                        {features
                                          .filter(
                                            (f) => selected.indexOf(f.ID) > -1,
                                          )
                                          .map((f, i) => (
                                            <span key={i}>
                                              {f.Name}
                                              {i === selected.length - 1 ? (
                                                ''
                                              ) : (
                                                <span>,&nbsp;</span>
                                              )}
                                            </span>
                                          ))}
                                      </div>
                                    )}
                                  >
                                    {features.map((f) => (
                                      <MenuItem key={f.ID} value={f.ID}>
                                        <Checkbox
                                          checked={
                                            values.Features.indexOf(f.ID) > -1
                                          }
                                        />
                                        <ListItemText primary={f.Name} />
                                      </MenuItem>
                                    ))}
                                  </Select>
                                  {touched.Features && errors.Features && (
                                    <FormHelperText>
                                      {errors.Features}
                                    </FormHelperText>
                                  )}
                                </FormControl>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                style={{ padding: '16px 6px 0 6px' }}
                              >
                                <Typography variant="subtitle1">
                                  Company Address
                                </Typography>
                              </Grid>
                              <Grid item xs={4}>
                                <TextField
                                  label="Street # / Premise*"
                                  name="Premise"
                                  error={!!(errors.Premise && touched.Premise)}
                                  helperText={touched.Premise && errors.Premise}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.Premise}
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <TextField
                                  name="Street"
                                  error={!!(errors.Street && touched.Street)}
                                  helperText={touched.Street && errors.Street}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.Street}
                                  label="Street*"
                                  type="text"
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <TextField
                                  label="Unit # / Sub Premise"
                                  name="SubPremise"
                                  error={
                                    !!(errors.SubPremise && touched.SubPremise)
                                  }
                                  helperText={
                                    touched.SubPremise && errors.SubPremise
                                  }
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.SubPremise}
                                  type="text"
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <TextField
                                  label="City / Locality *"
                                  name="Locality"
                                  error={
                                    !!(errors.Locality && touched.Locality)
                                  }
                                  helperText={
                                    touched.Locality && errors.Locality
                                  }
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.Locality}
                                  type="text"
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <TextField
                                  name="PostalCode"
                                  error={
                                    !!(errors.PostalCode && touched.PostalCode)
                                  }
                                  helperText={
                                    touched.PostalCode && errors.PostalCode
                                  }
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.PostalCode}
                                  label="Postal Code / Zip*"
                                  type="text"
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={4}></Grid>
                              <Grid item xs={4}>
                                <FormControl
                                  style={{ width: '100%' }}
                                  error={!!(errors.Country && touched.Country)}
                                >
                                  <InputLabel>Country*</InputLabel>
                                  <Select
                                    name="Country"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.Country}
                                  >
                                    {countries.map((c) => (
                                      <MenuItem key={c.ID} value={c.ID}>
                                        {c.Name}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                  {touched.Country && errors.Country && (
                                    <FormHelperText>
                                      {errors.Country}
                                    </FormHelperText>
                                  )}
                                </FormControl>
                              </Grid>
                              {_regions.length !== 0 && (
                                <Grid item xs={4}>
                                  <FormControl
                                    style={{ width: '100%' }}
                                    error={!!(errors.Region && touched.Region)}
                                  >
                                    <InputLabel>Region / Province</InputLabel>
                                    <Select
                                      name="Region"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.Region}
                                    >
                                      {_regions.map((r, i) => (
                                        <MenuItem key={i} value={r.ID}>
                                          {r.Name}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                    {touched.Region && errors.Region && (
                                      <FormHelperText>
                                        {errors.Region}
                                      </FormHelperText>
                                    )}
                                  </FormControl>
                                </Grid>
                              )}
                            </Grid>
                            <Box paddingTop="16px" display="flex">
                              <Button
                                disabled={isSubmitting}
                                type="submit"
                                color="primary"
                                style={{ marginLeft: 'auto' }}
                                variant="contained"
                              >
                                Save Changes
                              </Button>
                            </Box>
                            {isSubmitting && <CircularProgress size={24} />}
                          </form>
                        );
                      }}
                    </Formik>
                  </Grid>
                </Grid>
              </Box>
            )}
          </Paper>

          <Paper
            variant="outlined"
            style={{
              padding: '16px 32px 32px 32px',
              marginBottom: 32,
            }}
            id="venues"
          >
            <Box padding="16px 0 32px 0">
              <Box display="flex">
                <Typography variant="h5">Venues</Typography>
                <Button
                  color="primary"
                  style={{ marginLeft: 'auto' }}
                  variant="contained"
                  size="small"
                  onClick={() => setVenueForm(true)}
                >
                  Create New Venue
                </Button>
              </Box>
              <TableBase
                rows={venues}
                columns={venuesConfig}
                scroll
                onRowClick={(row) => setVenueForm({ ...row.Address, ...row })}
              />
            </Box>
          </Paper>

          <Paper
            variant="outlined"
            style={{
              padding: '16px 32px 32px 32px',
              marginBottom: 32,
            }}
            id="people"
          >
            <Box padding="16px 0 32px 0">
              <Box display="flex">
                <Typography variant="h5">People</Typography>
                <Button
                  color="primary"
                  style={{ marginLeft: 'auto' }}
                  variant="contained"
                  size="small"
                  onClick={() => setPersonForm(true)}
                >
                  Give Access to Existing User
                </Button>
              </Box>
              <TableBase
                scroll
                rows={access}
                columns={peopleConfig}
                onRowClick={(row) => setPersonForm(row)}
              />
            </Box>
          </Paper>
        </Grid>
      </Grid>
      <CreateCompany
        open={!!venueForm}
        handleClose={() => setVenueForm(false)}
        submit={(values) => {
          const payload = {
            ...values,
            CompanyID: parseInt(match.params.id, 10),
            Address: {
              ID: values.AddressID,
              Premise: values.Premise,
              Street: values.Street,
              SubPremise: values.SubPremise,
              Locality: values.Locality,
              PostalCode: values.PostalCode,
              Country: values.Country,
              Region: values.Region,
              SubRegion: values.SubRegion,
            },
          };
          if (!!values.ID) {
            updateCompanyVenue(payload).then((r) => {
              if (r instanceof Error) {
                return alert(r);
              }
              alert('Venue Info is updated!');
              setVenueForm(false);
            });
          } else {
            createCompanyVenue(payload).then((r) => {
              if (r instanceof Error) {
                return alert(r);
              }
              alert('Venue is created!');
              setVenueForm(false);
            });
          }
        }}
        title="Create New Venue"
        initial={venueForm}
        type="venue"
        regions={regions}
        countries={countries}
      />
      <Dialog
        open={!!personForm}
        onClose={() => setPersonForm(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Give access to an existing user
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={
              personForm === true
                ? {
                    VenueID: -1,
                    Email: '',
                    Super: false,
                    GM: false,
                    Manager: false,
                    Runner: false,
                    Scoring: false,
                    Blocked: false,
                  }
                : personForm
            }
            onSubmit={(values, { setSubmitting }) => {
              const venue = venues.find((v) => v.ID === values.VenueID);
              const payload = {
                ...values,
                CompanyID: match.params.id,
                CountryID: venue && venue.Address && venue.Address.Country,
              };

              addAccess(payload).then((r) => {
                setSubmitting(false);
                if (r instanceof Error) {
                  return alert(r);
                }
                if (r.status > 399) {
                  return alert(r.message);
                }
                alert(
                  personForm === true ? 'Access granted!' : 'Access updated!',
                );
                setPersonForm(false);
              });
            }}
          >
            {({
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        autoFocus
                        helperText="Enter the email of the user you want to give access to."
                        label="Email"
                        name="Email"
                        type="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.Email}
                        disabled={personForm !== true}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl style={{ width: '100%' }}>
                        <InputLabel>Default Venue</InputLabel>
                        <Select
                          name="VenueID"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.VenueID}
                        >
                          {venues.map((c) => (
                            <MenuItem key={c.ID} value={c.ID}>
                              {c.Name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormGroup row>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.Super}
                              name="Super"
                              color="primary"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              disabled
                            />
                          }
                          label="Super"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="GM"
                              color="primary"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              checked={!!values.GM}
                            />
                          }
                          label="GM"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="Manager"
                              color="primary"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              checked={!!values.Manager}
                            />
                          }
                          label="Manager"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="Runner"
                              color="primary"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              checked={!!values.Runner}
                            />
                          }
                          label="Runner"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="Scoring"
                              color="primary"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              checked={!!values.Scoring}
                            />
                          }
                          label="Scoring"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="Blocked"
                              color="primary"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              checked={!!values.Blocked}
                            />
                          }
                          label="Blocked"
                        />
                      </FormGroup>
                    </Grid>
                  </Grid>
                  <Box display="flex">
                    {isSubmitting && <CircularProgress size={24} />}
                    {personForm !== true && (
                      <Button
                        color="secondary"
                        onClick={() => {
                          if (
                            window.confirm(
                              `Removing access for ${personForm.Email}. Please confirm.`,
                            )
                          ) {
                            removeAccess(
                              values.AccessCompanyID,
                              parseInt(match.params.id, 10),
                              personForm.PlayerID,
                              values.ID,
                            ).then((r) => {
                              if (r instanceof Error) {
                                return alert(r);
                              }
                              if (r.status > 399) {
                                return alert(r.message);
                              }
                              alert('Access roveked!');
                              setPersonForm(false);
                            });
                          }
                        }}
                      >
                        Revoke Access
                      </Button>
                    )}
                    <Button
                      color="primary"
                      type="submit"
                      style={{ marginLeft: 'auto' }}
                    >
                      {personForm === true ? 'Add User' : 'Update Access'}
                    </Button>
                  </Box>
                </form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};
