import { propOr, isNil } from 'ramda';
import { store } from '../../';
import { logout } from 'src/scenes/login/store/login.action';

const keyValueToParamString = ({ key, value }) =>
  `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;

const queryParamsToQueryString = (params) => {
  if (!params) return '';

  return Object.keys(params)
    .reduce((result, key) => {
      const value = params[key];
      return isNil(value)
        ? result
        : result.concat(keyValueToParamString({ key, value }));
    }, [])
    .join('&');
};

const buildUrl = (url, params) => {
  const queryString = queryParamsToQueryString(params);
  return queryString.length === 0 ? url : `${url}?${queryString}`;
};

const defaultContentTypeHeaders = {
  'content-type': 'application/json',
};

const authorizationHeaders = (store) => ({
  authorization: `Bearer ${store.getState().auth.token}`,
});

const handleResponse = (res) =>
  res.ok
    ? res.json()
    : res.json().then((responseBody) => {
        if (res.status === 401) {
          store.dispatch(logout());
        }

        return Promise.reject({
          url: res.url,
          message: propOr(undefined, 'error')(responseBody),
          status: res.status,
          statusText: res.statusText,
        });
      });

export const postJSON = (url, body, headers) =>
  fetch(url, {
    method: 'POST',
    headers: {
      ...authorizationHeaders(store),
      ...defaultContentTypeHeaders,
      ...headers,
    },
    body: JSON.stringify(body),
  }).then(handleResponse);

export const putJSON = (url, body, headers) =>
  fetch(url, {
    method: 'PUT',
    headers: {
      ...authorizationHeaders(store),
      ...defaultContentTypeHeaders,
      ...headers,
    },
    body: JSON.stringify(body),
  }).then(handleResponse);

export const getJSON = (url, queryParams, headers) => {
  const urlWithQueryParams = buildUrl(url, queryParams);
  return fetch(`${urlWithQueryParams}`, {
    method: 'GET',
    headers: {
      ...authorizationHeaders(store),
      ...defaultContentTypeHeaders,
      ...headers,
    },
  }).then(handleResponse);
};

export const deleteJSON = (url, queryParams, headers) => {
  const urlWithQueryParams = buildUrl(url, queryParams);
  return fetch(`${urlWithQueryParams}`, {
    method: 'DELETE',
    headers: {
      ...authorizationHeaders(store),
      ...defaultContentTypeHeaders,
      ...headers,
    },
  }).then(handleResponse);
};
