import {
  EXPORT_BOOKINGS_FROM_SOURCE,
  IMPORT_EVENTS_TO_AXESCORES,
  GET_BOOKING_ACCESS,
  SET_BOOKING_ACCESS,
  GET_AUTHORIZED_VENUES,
  GET_ARENAS,
} from './events-import.constant';

const initialState = {
  arenas: null,
  venues: null,
  bookings: null,
  events: null,
  contests: null,
  bookingsLoading: false,
  bookingsError: null,
  bookingsImportLoading: false,
  bookingsImportError: null,
  bookingSources: null,
  access: null,
  accessError: null,
};

export const eventsImportReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case GET_ARENAS.SUCCESS:
      return {
        ...state,
        arenas: payload,
      };
    case GET_AUTHORIZED_VENUES.SUCCESS:
      return {
        ...state,
        venues: payload,
      };
    case GET_BOOKING_ACCESS.START:
      return {
        ...state,
        bookingSources: null,
        access: null,
      };
    case GET_BOOKING_ACCESS.SUCCESS:
      return {
        ...state,
        bookingSources: payload.sources,
        access: payload.access,
      };

    case GET_BOOKING_ACCESS.ERROR:
      return {
        ...state,
        accessError: payload,
      };

    case SET_BOOKING_ACCESS.SUCCESS:
      return {
        ...state,
        accessError: null,
      };

    case SET_BOOKING_ACCESS.ERROR:
      return {
        ...state,
        accessError: payload,
      };

    case EXPORT_BOOKINGS_FROM_SOURCE.START:
      return {
        ...state,
        bookingsLoading: true,
      };

    case EXPORT_BOOKINGS_FROM_SOURCE.ERROR:
      return {
        ...state,
        bookingsLoading: false,
        bookingsError: payload,
      };
    case EXPORT_BOOKINGS_FROM_SOURCE.SUCCESS:
      return {
        ...state,
        bookingsLoading: false,
        ...payload,
      };

    case IMPORT_EVENTS_TO_AXESCORES.SUCCESS:
      return {
        ...state,
        contests: payload,
        bookingsImportLoading: false,
      };

    case IMPORT_EVENTS_TO_AXESCORES.START:
      return {
        ...state,
        bookingsImportLoading: true,
      };

    case IMPORT_EVENTS_TO_AXESCORES.ERROR:
      return {
        ...state,
        bookingsImporting: false,
        bookingsImportError: payload,
      };

    default:
      return state;
  }
};
